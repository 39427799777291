import { createSlice } from '@reduxjs/toolkit'
import { setUserSettingsProp } from "../helpers/LocalStorageHelper"
import placeholder from "../resources/placeholder.png";

export const themeModes = {
  light: 'light',
  dark: 'dark'
}

const initialState = {
  themeMode: themeModes.light,
  listView: true,
  longLogo: true,
  settingsFeOpen: false,
  settingsViewerOpen: false,
  menuMobileOpen: false,
  modalOpen: false,
  modalTitle: "ModalTitle",
  modalImage: placeholder,
  modalContent: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis reprehenderit numquam sit suscipit, consequuntur porro autem voluptatum excepturi aliquam laudantium, distinctio error impedit commodi vel earum nam id inventore? Nobis.",
  modalLegalOpen: false,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleThemeMode: state => {
      state.themeMode = (state.themeMode === themeModes.light) ?
        themeModes.dark : themeModes.light
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload
    },
    toggleListView: state => {
      state.listView = !state.listView;
    },
    setLongLogo: (state, action) =>{
      state.longLogo = action.payload;
    },
    setSettingsFeOpen: (state, action) => {
      state.settingsFeOpen = action.payload;
    },
    setSettingsViewerOpen: (state, action) => {
      state.settingsViewerOpen = action.payload;
    },
    setMenuMobileOpen: (state, action) => {
      state.menuMobileOpen = action.payload
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload
    },
    setModalTitle: (state, action) => {
      state.modalTitle = action.payload
    },
    setModalImage: (state, action) => {
      state.modalImage = action.payload
    },
    setModalContent: (state, action) => {
      state.modalContent = action.payload
    },
    setModalLegalOpen: (state, action) => {
      state.modalLegalOpen = action.payload
    }
  }
})

export const {
  toggleThemeMode,
  setThemeMode,
  toggleListView,
  setSettingsFeOpen,
  setSettingsViewerOpen,
  setLongLogo,
  setMenuMobileOpen,
  setModalOpen,
  setModalTitle,
  setModalImage,
  setModalContent,
  setModalLegalOpen
 } = themeSlice.actions

export const toggleThemeModePersist = () => (dispatch, getState) => {
  dispatch(toggleThemeMode())
  const state = getState()
  setUserSettingsProp('themeMode', state.theme.themeMode)
}

export default themeSlice.reducer
