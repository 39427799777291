import AppLocalizationProvider from "./components/localization/AppLocalizationProvider"
import AppThemeProvider from "./components/theme/AppThemeProvider"
import { CssBaseline } from "@mui/material"
import AppSnackbarProvider from "./components/snackbar/AppSnackbarProvider"
import OfflineNotifier from "./components/offline/OfflineNotifier"
import AppBackdrop from "./components/backdrop/AppBackdrop"
import AppLoader from "./components/loader/AppLoader"
import AppError from "./components/error/AppError"
import AppRouter from "./routes/AppRouter"


export default function App() {
  return (
    <AppLocalizationProvider>
      <AppThemeProvider>
        <CssBaseline />
        <AppSnackbarProvider>
          <OfflineNotifier />
          <AppBackdrop />
          <AppLoader />
          <AppError />
          <AppRouter />
        </AppSnackbarProvider>
      </AppThemeProvider>
    </AppLocalizationProvider>
  )
}
