import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

export default function SnackbarCloseButton(props) {
  const { snackKey } = props
  const { closeSnackbar } = useSnackbar()

  const handleClose = () => closeSnackbar(snackKey)

  return (
    <IconButton
      onClick={handleClose}
      color='inherit'
      size="small"
    >
      <CloseOutlinedIcon fontSize="small" color="grey"/>
    </IconButton>
  )
}
