
import { setBackdrop, unsetBackdrop } from "../slices/backdropSlice"
// import { setLoader, unsetLoader } from "../slices/loaderSlice"
import { store } from "../store/store"
import data_static from "../helpers/data_example.json";
import api from "../api/Api";
import { getErrorMessage } from "../api/ApiDataHelper";
import { setSnackbarError } from "../slices/snackbarSlice";

let viewerInstance
let viewerContainer

// workaround for cache busting
const version = '20241016'

const createViewerInstance = async () => {
  let data = "";
  try {
    const response = await api.getData();
    // const result = await response.text();
    const result = JSON.stringify(response);
    data = JSON.parse(result);

    // HOT FIX TO TRY NEW MATERIALS | TO REMOVE BEFORE PRODUCTION
    // data = data_static;

    //FOR DEBUG
  }catch(error){
    store.dispatch(setSnackbarError(getErrorMessage(error)))
    data = data_static;
  }

  const containerID = 'viewer3DContainer'
  viewerContainer = document.createElement('div')
  viewerContainer.id = containerID
  viewerContainer.classList.add('viewer3D')
  viewerContainer.style.display = 'none'
  document.body.appendChild(viewerContainer)

  const config = {
    // the id of the container where the component will live
    containerID,
    limitedOrbit: true,
    isCameraIn: true,
  }

  viewerInstance = new window.Viewer3D(config)

  const state = store.getState();

  const doorSize = state.viewer.doorSize;
  const doorModelName = state.viewer.doorModelName;
  const doorAccessories = state.viewer.doorAccessories;
  const environmentStyle = state.viewer.environmentStyle;
  const configurationExterior_material = state.step.configurationExterior_material;
  const configurationInterior_material = state.step.configurationInterior_material;

  await viewerInstance.init(
    data,
    {
      modelName: doorModelName,
      accessories: doorAccessories,
      size: doorSize,
    },
    environmentStyle,
    configurationInterior_material,
    configurationExterior_material
  );

  const eventManager = viewerInstance.getEventManager()
  // viewerInstance.updateRenderAspect()

  eventManager.addEventListener("onMaterialChangeStarted", (e) => {
    store.dispatch(setBackdrop())
  });
  viewerContainer.addEventListener("loading", (e) => {
    // console.log("loading")
    store.dispatch(setBackdrop())
  });


  eventManager.addEventListener("onMaterialChangeCompleted", (e) => {
    store.dispatch(unsetBackdrop())
  });

  viewerContainer.addEventListener("loaded", (e) => {
    // console.log("loaded")
    store.dispatch(unsetBackdrop())
  });

  // viewerContainer.style.display = 'block'
  // viewerInstance.onResize();
}

const loadJS = src => {
  return new Promise((resolve, reject) => {
    let scriptElement = document.createElement('script')
    const id = new Date().getTime().toString()
    scriptElement.id = id
    scriptElement.src = src
    const firstScriptTag = document.getElementsByTagName('script')[0]
    scriptElement.addEventListener('load', resolve)
    firstScriptTag.parentNode.insertBefore(scriptElement, firstScriptTag)
  })
}

const initViewer = async () => {
  if (!viewerInstance) {
    const src = process.env.PUBLIC_URL + '/viewer3d.js?v=' + version
    await loadJS(src)
    await createViewerInstance()
  }
}

export {
  viewerInstance,
  viewerContainer,
  loadJS,
  initViewer
}
