// import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js"
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const getCustomTheme = (themeMode, theme) => {
  const light = themeMode === "light"
  const customTheme = {
    palette: {
      mode: themeMode,
      background: {
        default: light ? '#ffffff' : '#181818',
        paper: light ? '#ffffff' : '#181818',
      },
      primary: {
        main: '#02628B',
        light: "#0275A6"
      },
      secondary: {
        light: "#f7ae1a",
        main: '#F7A601',
        dark: "#de9500"
      },
      warning: {
        main: '#F7D448',
      },
      text: {
        main: '#000000DE',
      },
      snackbar: {
        main: '#182518',
      },
      white: {
        main: '#FAFAFA',
        dark: '#CCCCCC',
      },
      error: {
        main: "#FF3B30",
        dark: '#F7484E'
      },
      info: {
        main: '#333333'
      },
      brandGrey: {
        light: "rgba(217, 217, 217, 0.60)",
        main: '#E3E3E3',
        dark: '#6F6F6F'
      },
      grey:{
        main: "#949494",
      },
      ...(light ?
        {
          text: {
            primary: '#333333',
            white: "#FFFFFF",
            light: "#FAFAFA",
            grey: "#949494"
          }
        }
        : {
          text: {
            primary: '#FAFAFA',
            white: "#FFFFFF",
            light: "#FAFAFA",
            grey: "#949494"
          }
        }
      ),
    },
    typography: {
      fontFamily: 'futura-pt, sans-serif',
      h1: {
        fontSize: '4.25rem',
        fontWeight: '500',
        letterSpacing: '-1.36px',
        lineHeight: 1.0,
        [theme.breakpoints.down("md")]: {
          fontSize: '3.75rem',
          letterSpacing: '-0.6px',
        },
      },
      h2: {
        fontSize: '3.125rem',
        fontWeight: '500',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '2.75rem',
        },
      },
      h3: {
        fontSize: '2.357rem',
        fontWeight: '500',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '2.125rem',
        },
      },
      h4: {
        fontSize: '1.6875rem',
        fontWeight: '500',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '1.5rem',
        },
      },
      h5: {
        fontSize: '1.4375rem',
        fontWeight: '500',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '1.25rem',
        },
      },
      h6: {
        fontSize: '1.25rem',
        fontWeight: '500',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '1.25rem',
        },
      },
      body1: {
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '1rem',
        },
        '& strong': {
          fontWeight: '600',
        }
      },
      body2: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '0.875rem',
        },
        '& strong': {
          fontWeight: '600',
        }
      },
      button: {
        fontSize: '1rem',
        fontWeight: '400',
        borderRadius: '18px',
        letterSpacing: '1.92px',
        lineHeight: 1.3,
        [theme.breakpoints.down("md")]: {
          fontSize: '0.875rem',
        },
      },
      caption: {
        fontSize: '0.875rem',
        fontWeight: '400',
        letterSpacing: '0.14px',
        lineHeight: 1,
        [theme.breakpoints.down("md")]: {
          fontSize: '0.875rem',
        },
      },
      overline: {
        fontSize: '0.875rem',
        fontWeight: '300',
        textTransform: 'uppercase',
        letterSpacing: '1.4px',
        lineHeight: 1,
        [theme.breakpoints.down("md")]: {
          fontSize: '0.75rem',
        },
      },
      notes: {
        fontSize: '0.6875rem',
        fontWeight: '300',
        lineHeight: 1,
        [theme.breakpoints.down("md")]: {
          fontSize: '0.625rem',
        },
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          InputProps: {
            spellCheck: 'false'
          }
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            padding: "8px 16px",
            background: theme.palette.error.main + "1A",
            color: theme.palette.text.primary,
            width: "100%"
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .InputField": {
              borderRadius: "4px",
              position:"relative",
              // border: "1px solid red"
              '& .MuiFilledInput-root': {
                overflow: 'hidden',
                borderRadius: "4px",
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: theme.palette.divider,
                transition: theme.transitions.create([
                  'border-color',
                  'background-color',
                  'box-shadow',
                ]),
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focused': {
                  backgroundColor: 'transparent',
                  borderColor: theme.palette.primary.main,
                  "& select": {
                    backgroundColor: 'transparent',
                  }
                },
                "&.Mui-error": {
                  borderColor: theme.palette.error.main,
                },
                ".MuiInputBase-input":{
                  padding: "14px 0px 0px 0px",
                },
              },
              ".MuiInputBase-root": {
                // borderRadius: "8px",
                padding: "12px 16px",
                alignItems: "baseline",
              },
              ".MuiFormLabel-root": {
                top: "6px",
                left: "5px",
                fontFamily: 'futura-pt, sans-serif',
                fontSize: '1rem',
                fontWeight: '600',
                lineHeight: 1.3,
                letterSpacing: "0.14px",
                color: "#333333",
                [theme.breakpoints.down("md")]: {
                  fontSize: '0.875rem',
                },
                '& strong': {
                  fontWeight: '600',
                }
              },
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            pointerEvents: "auto",
            borderRadius: '4px',
            padding: "8px 16px",
            textTransform: 'none',
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            "&.MuiButton-text": {
              letterSpacing: "0.16px",
            },
            "&.MuiButton-contained": {
              letterSpacing: "1.92px",
              textTransform: "uppercase",
            },
            "&.MuiButton-outlined.chip": {
              letterSpacing: "0.32px",
              borderColor: theme.palette.divider,
              borderRadius: "20px",
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: "#F5F5F5",
                borderColor: theme.palette.grey.main,
              },
              "&.active": {
                borderColor: theme.palette.primary.main,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                }
              },
            },
            "&.BigButton": {
              padding: "18px 16px",
              borderRadius: "4px",
              letterSpacing: "1.92px",
              textTransform: "uppercase",
            }
            // textTransform: "uppercase"
          }
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
            letterSpacing: '0.19px',
          },
          icon: {
            width: '16px',
            height: '16px',
          },
          colorPrimary: {
            backgroundColor: '#4871F73D',
            color: '#4871F7',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: themeMode === 'light' ? '#00000059' : '#FFFFFF59',
            "&.Mui-checked": {
              svg: {
                borderRadius: '4px',
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&:not(.Mui-checked)": {
              svg: {
                fill: '#00000059',
              },
            },
          },
        },
      },
      MuiFromControl: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            letterSpacing: '1.2px',
            fontWeight: 400,
            color: '#4871F7',
          },
        },
      },
      MuiSimpleTreeView: {
        styleOverrides: {
          root: {
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiBox-root.box_transparent': {
              padding: '16px',
              borderRadius: '24px',
              // backdropFilter: 'blur(8px)',
              // backgroundColor: getTransparentBgColor(themeMode)
              backgroundColor: theme.palette.background.paper
            },
          }),
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.product_card': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            border: '0px !important',
            outline: themeMode === 'light' ? '1px solid #0000001F' : '1px solid #FFFFFF1F',
            '&.blur': {
              backdropFilter: 'blur(8px)',
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '&#back-to-top-anchor': {
              minHeight: "0px",
              height: "0px"
            }
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: 52,
            height: 32,
            padding: 0,
            '& .Mui-checked': {
              '+ .MuiSwitch-track': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.primary.main,
                opacity: 1,
              },
              '&:hover + .MuiSwitch-track': {
                background: theme.palette.primary.light,
              },
              '& .MuiSwitch-thumb': {
                background: theme.palette.text.white,
                '&:before': {
                  content: `""`,
                  backgroundPosition: 'center',
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.primary
                  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                  backgroundRepeat: "no-repeat"
                },
              },
              '&.MuiSwitch-switchBase': {
                transform: "translateX(20px)",
              },
            },
            '& .MuiSwitch-switchBase': {
              padding: "0",
              margin: "4px",
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 24,
              height: 24,
              padding: 2,
              background: theme.palette.text.grey,
              '&:before': {
                content: `""`,
                boxSizing: 'border-box',
                position: "absolute",
                display: "block",
                width: 20,
                height: 20,
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                  theme.palette.text.white,
                )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`,
                backgroundRepeat: "no-repeat"
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: 32 / 2,
              opacity: 1,
              background: theme.palette.text.light,
              border: "1px solid",
              borderColor: theme.palette.brandGrey.light,
            },
          })
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&.overviewerButton": {
              pointerEvents: "auto",
              background: theme.palette.background.default,
              borderRadius: "4px",
              boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              color: theme.palette.text.primary,
              "&:hover": {
                boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
              },
              "&svg": {
                fill: "currentColor",
              }
            }
          })
        }
      },
      MuiTooltip:{
        styleOverrides: {
          popper: {
            ".MuiTooltip-tooltip": {
              backgroundColor: theme.palette.background.default,
              boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.10)",
              color: theme.palette.text.primary,
              padding: "8px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              lineHeight: "1.3",
              fontWeight: "400",
              letterSpacing: "0.32px"
            },
            ".MuiTooltip-arrow": {
              color: theme.palette.background.default,
              "&::before": {
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.10)",
              }
            }
          },
        },
      },
    },
  }
  return customTheme
}

export const getTransparentBgColor = mode =>
  mode === 'light' ? "#f5f5f4D9" : "#111111D9"
