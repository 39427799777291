import jsPDF from "jspdf";
import { getAssetImagesUrl, getFontUrl } from "../api/ApiDataHelper";
import { store } from "../store/store";
import { setPdfId } from "../slices/formSlice";
import i18n from "./i18n";
import { materials, steps } from "./StepsHelper";

export const generatePdfOld = async (download) => {
  const pdfId = Date.now().toString(16);
  store.dispatch(setPdfId(pdfId))
  const content = document.getElementById("printable");

  if (content) {

    // Usa html2canvas per convertire l'elemento HTML in un canvas
    // const canvas = await html2canvas(content);
    // const imgData = canvas.toDataURL('image/jpg');

    // Crea un nuovo documento jsPDF
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a1',
    });

    pdf.addFont(getFontUrl('Jost-Bold.ttf'), 'Jost', 'bold');
    pdf.addFont(getFontUrl('Jost-Medium.ttf'), 'Jost', 'normal');
    pdf.addFont(getFontUrl('Jost-Light.ttf'), 'Jost', 'light');
    pdf.setFont('Jost');
    // let pageHeight = pdf.internal.pageSize.getHeight();
    let pageWidth = pdf.internal.pageSize.getWidth();
    content.style.width = (pageWidth - 100) + 'px';
    await pdf.html(content, {
      x: 50,
      y: 50,
      windowWidth: pageWidth - 100,
    });
    // const imgProps = pdf.getImageProperties(imgData);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    // console.log(pdfWidth, pdfHeight)
    // Aggiungi l'immagine al PDF
    // pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, "MEDIUM");

    // Salva il PDF
    if (download) {
      pdf.save('gasperotti_' + pdfId + '.pdf');
    } else {
      return pdf;
    }
  }
};

export const generatePdf = async (download) => {
  const pdfId = Date.now().toString(16);
  store.dispatch(setPdfId(pdfId))
  const state = store.getState();

  const date = new Date();
  const date_future = new Date(Date.now() + 15 * 24 * 60 * 60 * 1000);
  const configurationExterior_material = materials[state.step.configurationExterior_materialType][state.step.configurationExterior_material]
  const configurationInterior_material = materials[state.step.configurationInterior_materialType][state.step.configurationInterior_material]
  // Usa html2canvas per convertire l'elemento HTML in un canvas
  // const canvas = await html2canvas(content);
  // const imgData = canvas.toDataURL('image/jpg');

  // Crea un nuovo documento jsPDF
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: 'a1',
  });

  pdf.addFont(getFontUrl('Jost-Bold.ttf'), 'Jost', 'bold');
  pdf.addFont(getFontUrl('Jost-Medium.ttf'), 'Jost', 'normal');
  pdf.addFont(getFontUrl('Jost-Light.ttf'), 'Jost', 'light');
  pdf.setFont('Jost');

  // HEADER

  // LOGO
  pdf.addImage(getAssetImagesUrl("logo_long_colored.png"), 'png', 48, 48, 138.78, 40, undefined, "MEDIUM");

  // FIRST COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20)
  pdf.setLineHeightFactor(1.27)
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_configuration_number"), 430, 48, {baseline: "top"});
  pdf.setLineHeightFactor(1.27)
  pdf.setFontSize(18)
  pdf.setTextColor(148, 148, 148);
  pdf.text(pdfId ? pdfId.toUpperCase() : Date.now().toString(16).toUpperCase(), 430, 71, {baseline: "top"});

  // SECOND COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20)
  pdf.setLineHeightFactor(1.27)
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_duration_title"), 810, 48, {baseline: "top"});
  pdf.setLineHeightFactor(1.27)
  pdf.setFontSize(18)
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t("label_created_on") + " " + date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear(), 810, 71, {baseline: "top"});
  pdf.text(i18n.t("label_duration_time") + " " + date_future.getDate() + "/" + (date_future.getMonth()+1) + "/" + date_future.getFullYear(), 810, 90, {baseline: "top"});

  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 129, 1192, 129 )

  // IMAGES TITLE
  pdf.setLineHeightFactor(1.27)
  pdf.setFontSize(18)
  pdf.setTextColor(2,98,139);
  pdf.text(i18n.t("label_preview_configuration"), 48, 147, {baseline: "top"});

  // IMAGES
  pdf.addImage(state.viewer.screenShotOutside, 'png', 48, 180, 572, 381.33, undefined, "MEDIUM");
  pdf.addImage(state.viewer.screenShotInside, 'png', 620, 180, 572, 381.33, undefined, "MEDIUM");

  pdf.setLineHeightFactor(1.27)
  pdf.setFontSize(18)
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t("label_outside_view"), 48, 568, {baseline: "top"});
  pdf.text(i18n.t("label_inside_view"), 620, 568, {baseline: "top"});

  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 588, 1192, 588 );

  // PRODUCTS SECTION
  // TITLE SECTION
  pdf.setLineHeightFactor(1.27);
  pdf.setFontSize(18);
  pdf.setTextColor(2,98,139);
  pdf.text(i18n.t("label_products_compatibility"), 48, 613, {baseline: "top"});

  // PRODUCT FIRST ROW
  pdf.setFont('Jost', 'normal');
  pdf.setFontSize(18);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_products_compatible_configuration"), 48, 650, {baseline: "top", maxWidth: 381});
  //PRODUCT 1 & 2 TITLE
  pdf.setFont('Jost', 'normal');
  pdf.setFontSize(40);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.name), 430, 650, {baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.name), 810, 650, {baseline: "top", maxWidth: 381});
  // SECURITY
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_security"), 48, 725, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.security), 430, 725, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.security), 810, 725, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 750, 1192, 750 );
  // ACUSTIC
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_acoustic"), 48, 765, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.acoustic), 430, 765, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.acoustic), 810, 765, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 790, 1192, 790 );
  // THERMAL
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_thermal"), 48, 805, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.thermal), 430, 805, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.thermal), 810, 805, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 830, 1192, 830 );
  // AIR
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_air"), 48, 845, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.air), 430, 845, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.air), 810, 845, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 870, 1192, 870 );
  // WATER
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_water"), 48, 885, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.water), 430, 885, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.water), 810, 885, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 910, 1192, 910 );
  // WIND
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("label_model_info_wind"), 48, 925, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(state.step.compatibleProducts[0]?.wind), 430, 925, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  pdf.text(i18n.t(state.step.compatibleProducts[1]?.wind), 810, 925, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 950, 1192, 950 );
  //CONFIGURATION
  // TITLE SECTION
  pdf.setLineHeightFactor(1.27);
  pdf.setFontSize(18);
  pdf.setTextColor(2,98,139);
  pdf.text(i18n.t("label_configuration_recap"), 48, 984, {baseline: "top"});
  // FIRST ROW
  //FIRST COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[2].description), 48, 1036, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[2].content.input_title), 48, 1054, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(String(i18n.t(steps[2].content.input_values[0].label))[0].toUpperCase() + " " + state.step.configurationWidth + " cm • " + String(i18n.t(steps[2].content.input_values[1].label))[0].toUpperCase() + " " + state.step.configurationHeight + " cm", 48, 1075, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //SECOND COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[2].description), 430, 1036, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[2].content.selectable_title), 430, 1054, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[2].content.selectable_values[state.step.configurationHinge].title), 430, 1075, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //THIRD COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[3].description), 810, 1036, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[3].title), 810, 1054, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[3].content.selectable_values[state.step.configurationSide].title), 810, 1075, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1105, 1192, 1105 );
  //SECOND ROW
  //FIRST COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[4].description), 48, 1125, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[4].title), 48, 1144, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[4].content.selectable_values[state.step.configurationExterior_type].title), 48, 1165, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //SECOND COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[5].description), 430, 1125, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[5].title), 430, 1144, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[4].content.selectable_values[state.step.configurationExterior_type].title), 430, 1165, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //THIRD COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[6].description), 810, 1125, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[6].title), 810, 1144, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(state.step.configurationExterior_materialType) + " • " + configurationExterior_material?.code, 810, 1165, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1205, 1192, 1205 );
  //THIRD ROW
  //FIRST COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[7].description), 48, 1225, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[7].title), 48, 1244, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(state.step.configurationInterior_materialType) + " • " + configurationInterior_material?.code, 48, 1265, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //SECOND COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[8].description), 430, 1225, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[8].title), 430, 1244, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[8].content.selectable_values[state.step.configurationHandleExternal]?.title), 430, 1265, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //THIRD COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[9].description), 810, 1225, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[9].title), 810, 1244, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(steps[9].content.selectable_values[state.step.configurationHandleInternal]?.title), 810, 1265, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1305, 1192, 1305 );
  //FORTH ROW
  //FIRST COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[10].description), 48, 1325, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[10].title), 48, 1344, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(state.step.configurationAutoCloser ? "label_yes" : "label_no"), 48, 1365, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //SECOND COLUMN
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(16);
  pdf.setLineHeightFactor(1.27);
  pdf.setTextColor(148, 148, 148);
  pdf.text(i18n.t(steps[11].description), 430, 1325, {charSpace: 0.14, baseline: "top", maxWidth: 381});
  pdf.setFontSize(18);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t(steps[11].title), 430, 1344, {charSpace: 0.32, baseline: "top", maxWidth: 381});
  pdf.setFontSize(20);
  pdf.text(i18n.t(state.step.configurationAccessories === false ? "label_manual" : "label_automatic") + (state.step.configurationAccessories === false ? "" : " • " + i18n.t(state.step.configurationAccessories.title)), 430, 1365, {charSpace: 0.36, baseline: "top", maxWidth: 381});
  //THIRD COLUMN
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1405, 1192, 1405 );
  // SPACE BETWEEN
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1550, 1192, 1550 );
  //CTA MAIL
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("cta_request_your_quote_at"), 330, 1590, {charSpace: 0.36, baseline: "top", maxWidth: 572, align: "center"});
  pdf.setFontSize(30);
  pdf.text(i18n.t("mail_orders"), 330, 1610, {charSpace: 0.36, baseline: "top", maxWidth: 572, align: "center"});
  pdf.link(48, 1610, 500, 20, {url: "mailto:" + i18n.t("mail_orders")} )
  // DIVIDER VERTICAL
  pdf.setDrawColor(232, 232, 232);
  pdf.line(620, 1555, 620, 1660 );
  //CTA MAIL
  pdf.setFont('Jost', 'light');
  pdf.setFontSize(20);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("cta_find_the_closest_store_near_you"), 900, 1590, {charSpace: 0.36, baseline: "top", maxWidth: 572, align: "center"});
  pdf.setFontSize(30);
  pdf.text(i18n.t("link_stores"), 900, 1610, {charSpace: 0.36, baseline: "top", maxWidth: 572, align: "center"});
  pdf.link(620, 1610, 500, 20, {url: "https://" + i18n.t("link_stores")} )
  // DIVIDER
  pdf.setDrawColor(232, 232, 232);
  pdf.line(48, 1670, 1192, 1670 );
  // FOOTER
  pdf.setFontSize(20);
  pdf.setTextColor(51, 51, 51);
  pdf.text(i18n.t("site_info"), 670, 1680, {charSpace: 0.36, baseline: "top", maxWidth: 1144, align: "center"})

  // const imgProps = pdf.getImageProperties(imgData);
  // const pdfWidth = pdf.internal.pageSize.getWidth();
  // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  // console.log(pdfWidth, pdfHeight)

  // Salva il PDF
  if (download) {
    pdf.save('gasperotti_' + pdfId + '.pdf');
  } else {
    return pdf;
  }

};
