// import { loadResources } from "i18next";
import placeholderImage from "../resources/placeholder.png";
import icon_door_0 from "../resources/icon_door_0.png";
import icon_door_1cd from "../resources/icon_door_1cd.png";
import icon_door_1l from "../resources/icon_door_1l.png";
import icon_door_1c from "../resources/icon_door_1c.png";
import icon_door_3c from "../resources/icon_door_3c.png";
import icon_door_3l from "../resources/icon_door_3l.png";
import icon_door_4c from "../resources/icon_door_4c.png";
import icon_door_4l from "../resources/icon_door_4l.png";
import maniglia_quadrata from "../resources/maniglia_quadrata.jpg";
import maniglia_rotonda from "../resources/maniglia_rotonda.jpg";
import maniglione_quadrato from "../resources/maniglione_quadrato.jpg";
import maniglione_rotondo from "../resources/maniglione_rotondo.jpg";
import cerniere_a_scomparsa from "../resources/cerniere_a_scomparsa.jpg"
import cernienre_a_vista from "../resources/cernienre_a_vista.jpg"
import verso_apertura_cerniera_destra from "../resources/verso_apertura_cerniera_destra.jpg";
import verso_apertura_cerniera_sinistra from "../resources/verso_apertura_cerniera_sinistra.jpg";
import accessorio_boccola from "../resources/accessorio_boccola.jpg";
import accessorio_tastierino_quadrato from "../resources/accessorio_tastierino_quadrato.jpg";
import accessorio_tastierino_ovale from "../resources/accessorio_tastierino_ovale.jpg"
import accessorio_tastierino_vetrato from "../resources/accessorio_tastierino_vetrato.jpg"
import accessorio_lettore_biometrico from "../resources/accessorio_lettore_biometrico.jpg"
import image_autocloser from "../resources/image_autocloser.png";
// import { getAssetImagesUrl } from "../api/ApiDataHelper";

// DATA STRUCTURE THAT DESCRIBE EVERY STEP IN THE CONFIGURATION PROCESS
export const steps = {
  0: {
    title: "step_0_title",
    description: "step_0_description",
  },
  // EXPOSITION
  1: {
    title: "step_1_title",
    description: "step_1_description",
    content: {
      icons: [
        "icon_weather_cloud",
        "icon_weather_snow",
        "icon_weather_rain",
        "icon_weather_sun",
      ],
      text: "step_1_content_text",
      text_next: "step_1_content_text_next",
    }
  },
  // SIZES
  2: {
    title: "step_2_title",
    description: "step_2_description",
    content: {
      input_title: "step_2_input_title",
      input_values: [
        {
          id: "width",
          label: "step_2_label_width",
          default_value: 90,
          min_value: 80,
          max_value: 110,
          sunny_min_value: 80,
          sunny_max_value: 100,
          measure: "cm",
          error_message: "step_2_error_width",
          sunny_error_message: "step_2_error_width_sunny",
        },
        {
          id: "height",
          label: "step_2_label_height",
          default_value: 200,
          min_value: 200,
          max_value: 248,
          sunny_min_value: 200,
          sunny_max_value: 220,
          measure: "cm",
          error_message: "step_2_error_height",
          sunny_error_message: "step_2_error_height_sunny",
        }
      ],
      selectable_title: "step_2_selectable_title",
      selectable_info: {
        title: "step_2_info_title",
        image: placeholderImage,
        description: "step_2_info_description",
      },
      selectable_values: [
        {
          id: "visible",
          title: "step_2_label_visible",
          value: "visibleValue",
          image: cernienre_a_vista,
        },
        {
          id: "hidden",
          title: "step_2_label_hidden",
          value: "hiddenValue",
          image: cerniere_a_scomparsa,
        }
      ]
    },
  },
  // OPENING SIDE
  3: {
    title: "step_3_title",
    description: "step_3_description",
    content: {
      selectable_values: [
        {
          id: "left",
          title: "step_3_label_left",
          description: "step_3_label_left_description",
          value: "leftValue",
          image: verso_apertura_cerniera_sinistra,
        },
        {
          id: "right",
          title: "step_3_label_right",
          description: "step_3_label_right_description",
          value: "rightValue",
          image: verso_apertura_cerniera_destra,
        }
      ]
    },
  },
  //EXTERNAL PANNEL
  4: {
    title: "step_4_title",
    description: "step_4_description",
    content: {
      selectable_values: [
        {
          id: "icon_door_0",
          title: "label_door_0",
          image: icon_door_0,
          modelHiddenHinge: "SUNNY-HABITAT_L_0_C",
          modelVisibleHinge: null,
          value: "visibleValue",
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["habitat"],
          compatibleModelsHiddenHinge: ["habitat"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_1cd",
          title: "label_door_1cd",
          image: icon_door_1cd,
          value: "visibleValue",
          modelHiddenHinge: null,
          modelVisibleHinge: "SUNNY-F_1_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: true,
          preselectedModelsSupported: ["sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunny", "sunnytop"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_1c",
          title: "label_door_1c",
          image: icon_door_1c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_1_C",
          modelVisibleHinge: "SUNNY-L_1_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_1l",
          title: "label_door_1l",
          image: icon_door_1l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_1_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_3c",
          title: "label_door_3c",
          image: icon_door_3c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_3_C",
          modelVisibleHinge: "SUNNY-L_3_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_3l",
          title: "label_door_3l",
          image: icon_door_3l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_3_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_4c",
          title: "label_door_4c",
          image: icon_door_4c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_4_C",
          modelVisibleHinge: "SUNNY-L_4_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_4l",
          title: "label_door_4l",
          image: icon_door_4l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_4_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
      ]
    },
  },
  //INTERNAL PANNEL
  5: {
    title: "step_5_title",
    description: "step_5_description",
    content: {
      text: "step_5_text",
      text_back: "step_5_text_back",
      selectable_values: [
        {
          id: "icon_door_0",
          title: "label_door_0",
          image: icon_door_0,
          modelHiddenHinge: "SUNNY-HABITAT_L_0_C",
          modelVisibleHinge: null,
          value: "visibleValue",
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["habitat"],
          compatibleModelsHiddenHinge: ["habitat"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_1cd",
          title: "label_door_1cd",
          image: icon_door_1cd,
          value: "visibleValue",
          modelHiddenHinge: null,
          modelVisibleHinge: "SUNNY-F_1_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: true,
          preselectedModelsSupported: ["sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunny", "sunnytop"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_1c",
          title: "label_door_1c",
          image: icon_door_1c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_1_C",
          modelVisibleHinge: "SUNNY-L_1_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_1l",
          title: "label_door_1l",
          image: icon_door_1l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_1_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_3c",
          title: "label_door_3c",
          image: icon_door_3c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_3_C",
          modelVisibleHinge: "SUNNY-L_3_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_3l",
          title: "label_door_3l",
          image: icon_door_3l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_3_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
        {
          id: "icon_door_4c",
          title: "label_door_4c",
          image: icon_door_4c,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_4_C",
          modelVisibleHinge: "SUNNY-L_4_C",
          hingeVisibleSupported: true,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom", "sunny", "sunnytop"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: ["sunny", "sunnytop"],
        },
        {
          id: "icon_door_4l",
          title: "label_door_4l",
          image: icon_door_4l,
          value: "visibleValue",
          modelHiddenHinge: "SUNNY-COM_L_4_D",
          modelVisibleHinge: null,
          hingeVisibleSupported: false,
          hingeHiddenNotSupported: false,
          preselectedModelsSupported: ["sunnycom"],
          compatibleModelsHiddenHinge: ["sunnycom"],
          compatibleModelsVisibleHinge: [],
        },
      ]
    },
  },
  //EXTERNAL MATERIAL
  6: {
    title: "step_6_title",
    description: "step_6_description",
    content: {
      text_single_value: "step_6_text_single_value",
      text_back: "step_6_text_back",
      color_filters: [
        {
          name: "color_label_yellow",
          value: "yellow",
          color: "#FAD02E"
        },
        {
          name: "color_label_brown",
          value: "brown",
          color: "#BF7F35"
        },
        {
          name: "color_label_orange",
          value: "orange",
          color: "#FF7A29"
        },
      ],
    },
  },
  //INTERNAL MATERIAL
  7: {
    title: "step_7_title",
    description: "step_7_description",
    content: {
      text_description_hidden_hinge: "steps_7_text_only_material_available",
      text_back: "steps_7_text_back_to_hinge",
      color_filters: [
        {
          name: "color_label_yellow",
          value: "yellow",
          color: "#FAD02E"
        },
        {
          name: "color_label_brown",
          value: "brown",
          color: "#BF7F35"
        },
        {
          name: "color_label_orange",
          value: "orange",
          color: "#FF7A29"
        },
      ],
    },
  },
  //EXTERNAL HANDLE
  8: {
    title: "step_8_title",
    description: "step_8_description",
    content: {
      selectable_values: [
        {
          id: "handle_01",
          image: maniglione_rotondo,
          model: "ManiglioneRotondo",
          title: "name_external_handle_round_1",
          description: "description_external_handle_round_1",
          hinge_depency: 0
        },
        {
          id: "handle_02",
          image: maniglione_quadrato,
          model: "ManiglioneQuadrato",
          title: "name_external_handle_squared_1",
          description: "description_external_handle_squared_1",
          hinge_depency: 1
        }, {
          id: "handle_03",
          image: maniglia_quadrata,
          model: "ManigliaQuadrataEsterno",
          title: "name_external_handle_squared_2",
          description: "description_external_handle_squared_2",
          hinge_depency: 1
        },
        {
          id: "handle_04",
          image: maniglia_rotonda,
          model: "ManiglioneRotondaEsterno",
          title: "name_external_handle_round_2",
          description: "description_external_handle_round_2",
          hinge_depency: 0
        },
      ]
    }
  },
  //INTERNAL HANDLE
  9: {
    title: "step_9_title",
    description: "step_9_description",
    content: {
      selectable_values: [
        {
          id: "handle_01",
          image: maniglia_rotonda,
          title: "name_internal_handle_round",
          description: "description_internal_handle_round",
        },
        {
          id: "handle_02",
          image: maniglia_quadrata,
          title: "name_internal_handle_squared",
          description: "description_internal_handle_squared",
        },
      ]
    }
  },
  //AUTO CLOSER
  10: {
    title: "step_10_title",
    description: "step_10_description",
    content: {
      image: image_autocloser,
      text: "step_10_text",
    }
  },
  //CLOSING MODE AND ACCESSORIES
  11: {
    title: "step_11_title",
    description: "step_11_description",
    content: {
      text: "step_11_text",
      icons: ["icon_key", "icon_hand", "icon_key"],
      selectable_values: [
        {
          id: "accessory1",
          image: accessorio_tastierino_quadrato,
          title: "name_accessory_01",
          model: "TastierinoQuadrato",
          hingeVisibleSupported: false,
          hingeHiddenSupported: true,
        },
        {
          id: "accessory2",
          image: accessorio_tastierino_ovale,
          title: "name_accessory_02",
          model: "TastierinoOvale",
          hingeVisibleSupported: true,
          hingeHiddenSupported: false,
        },
        {
          id: "accessory3",
          image: accessorio_tastierino_vetrato,
          title: "name_accessory_03",
          model: "TastierinoVetrato",
          hingeVisibleSupported: true,
          hingeHiddenSupported: true,
        },
        {
          id: "accessory4",
          image: accessorio_lettore_biometrico,
          title: "name_accessory_04",
          model: "LettoreBiometrico",
          hingeVisibleSupported: true,
          hingeHiddenSupported: true,
        },
        {
          id: "accessory5",
          image: accessorio_boccola,
          title: "name_accessory_05",
          model: "BoccolaLettoreNascosto",
          hingeVisibleSupported: true,
          hingeHiddenSupported: false,
        },
        {
          id: "accessory6",
          image: accessorio_boccola,
          title: "name_accessory_06",
          model: "BoccolaLettoreNascostoInterno",
          hingeVisibleSupported: false,
          hingeHiddenSupported: true,
        },
      ]
    }
  },
  // CONFIGURATION SUMMARY
  12: {
    title: "step_12_title",
    description: "step_12_description",
    content: {
      compatibleProducts: {
        "sunnytop": {
          name: "label_product_description_name_sunnytop",
          security: "label_product_description_security_sunnytop",
          acoustic: "label_product_description_acoustic_sunnytop",
          thermal: "label_product_description_thermal_sunnytop",
          air: "label_product_description_air_sunnytop",
          water: "label_product_description_water_sunnytop",
          wind: "label_product_description_wind_sunnytop",
        },
        "sunny": {
          name: "label_product_description_name_sunny",
          security: "label_product_description_security_sunny",
          acoustic: "label_product_description_acoustic_sunny",
          thermal: "label_product_description_thermal_sunny",
          air: "label_product_description_air_sunny",
          water: "label_product_description_water_sunny",
          wind: "label_product_description_wind_sunny",
        },
        "habitat": {
          name: "label_product_description_name_habitat",
          security: "label_product_description_security_habitat",
          acoustic: "label_product_description_acoustic_habitat",
          thermal: "label_product_description_thermal_habitat",
          air: "label_product_description_air_habitat",
          water: "label_product_description_water_habitat",
          wind: "label_product_description_wind_habitat",
        },
        "sunnycom": {
          name: "label_product_description_name_sunnycom",
          security: "label_product_description_security_sunnycom",
          acoustic: "label_product_description_acoustic_sunnycom",
          thermal: "label_product_description_thermal_sunnycom",
          air: "label_product_description_air_sunnycom",
          water: "label_product_description_water_sunnycom",
          wind: "label_product_description_wind_sunnycom",
        }
      }
    }
  },
  // REQUEST FORM
  13: {
    title: "step_13_title",
    description: "",
  },
  // LAST STEP
  14: {
    title: "step_14_title",
    description: "",
  }
}

export const colorsFilter = [
  {
    value: "Rosso",
    hexColor: "#ff2929",
    name: {
      en: "red",
      de: "rot",
      it: "rosso",
    }
  },
  {
    value: "Arancione",
    hexColor: "#ff7a29",
    name: {
      en: "orange",
      de: "orange",
      it: "arancione",
    }
  },
  {
    value: "Marrone",
    hexColor: "#bf7f35",
    name: {
      en: "brown",
      de: "braun",
      it: "marrone",
    }
  },
  {
    value: "Giallo",
    hexColor: "#fad02e",
    name: {
      en: "yellow",
      de: "gelb",
      it: "giallo",
    }
  },
  {
    value: "Verde",
    hexColor: "#91fa49",
    name: {
      en: "green",
      de: "grün",
      it: "verde",
    }
  },
  {
    value: "Celeste",
    hexColor: "#36d8b7",
    name: {
      en: "cyan",
      de: "himmelblau",
      it: "celeste",
    }
  },
  {
    value: "Blu",
    hexColor: "#3b8aff",
    name: {
      en: "blue",
      de: "blau",
      it: "blu",
    }
  },
  {
    value: "Viola",
    hexColor: "#991ef9",
    name: {
      en: "violet",
      de: "violett",
      it: "viola",
    }
  },
  {
    value: "Rosa",
    hexColor: "#ff5dcd",
    name: {
      en: "pink",
      de: "pink",
      it: "rosa",
    }
  },
  {
    value: "Grigio",
    hexColor: "#b3bac1",
    name: {
      en: "grey",
      de: "grau",
      it: "grigio",
    }
  },
  {
    value: "Bianco",
    hexColor: "white",
    name: {
      en: "white",
      de: "weiß",
      it: "bianco",
    }
  },
  {
    value: "Nero",
    hexColor: "black",
    name: {
      en: "black",
      de: "schwarz",
      it: "nero",
    }
  },
]

export const materials = {
  label_Tekno_Material: {
    "Tekno_01": {
      code: "3118-076",
      model: "Tekno_01",
      img: "Tekno_01.jpg",
      hex: false,
      name: {
        en: "Eiche Natur FL-G",
        de: "Eiche Natur FL-G",
        fr: "Eiche Natur FL-G",
        it: "Eiche Natur FL-G"
      },
      color_filter: [
        "Marrone",
        "Giallo",
      ]
    },
    "Tekno_02": {
      code: "3211-005",
      model: "Tekno_02",
      img: "Tekno_02.jpg",
      hex: false,
      name: {
        en: "Irish Oak",
        de: "Irish Oak",
        fr: "Irish Oak",
        it: "Irish Oak"
      },
      color_filter: [
        "Marrone",
        "Giallo",
      ]
    },
    "Tekno_03": {
      code: "3069-041",
      model: "Tekno_03",
      img: "Tekno_03.jpg",
      hex: false,
      name: {
        en: "Bergkiefer",
        de: "Bergkiefer",
        fr: "Bergkiefer",
        it: "Bergkiefer"
      },
      color_filter: [
        "Marrone",
        "Giallo",
      ]
    },
    "Tekno_04": {
      code: "2115-008",
      model: "Tekno_04",
      img: "Tekno_04.jpg",
      hex: false,
      name: {
        en: "Oregon 3",
        de: "Oregon 3",
        fr: "Oregon 3",
        it: "Oregon 3"
      },
      color_filter: [
        "Marrone",
        "Giallo",
      ]
    },
    "Tekno_05": {
      code: "49198",
      model: "Tekno_05",
      img: "Tekno_05.jpg",
      hex: false,
      name: {
        en: "Shogun AF",
        de: "Shogun AF",
        fr: "Shogun AF",
        it: "Shogun AF"
      },
      color_filter: [
        "Marrone",
        "Giallo",
        "Arancione",
      ]
    },
    "Tekno_06": {
      code: "3152-009",
      model: "Tekno_06",
      img: "Tekno_06.jpg",
      hex: false,
      name: {
        en: "Streifen Douglasie",
        de: "Streifen Douglasie",
        fr: "Streifen Douglasie",
        it: "Streifen Douglasie"
      },
      color_filter: [
        "Marrone",
        "Arancione",
      ]
    },
    "Tekno_07": {
      code: "49240",
      model: "Tekno_07",
      img: "Tekno_07.jpg",
      hex: false,
      name: {
        en: "Winchester XA",
        de: "Winchester XA",
        fr: "Winchester XA",
        it: "Winchester XA"
      },
      color_filter: [
        "Marrone",
        "Arancione",
      ]
    },
    "Tekno_08": {
      code: "49237",
      model: "Tekno_08",
      img: "Tekno_08.jpg",
      hex: false,
      name: {
        en: "Siena PN",
        de: "Siena PN",
        fr: "Siena PN",
        it: "Siena PN"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_09": {
      code: "49254",
      model: "Tekno_09",
      img: "Tekno_09.jpg",
      hex: false,
      name: {
        en: "Siena PL",
        de: "Siena PL",
        fr: "Siena PL",
        it: "Siena PL"
      },
      color_filter: [
        "Marrone",
        "Arancione",]
    },

    "Tekno_10": {
      code: "2052-090",
      model: "Tekno_10",
      img: "Tekno_10.jpg",
      hex: false,
      name: {
        en: "Eiche Hell",
        de: "Eiche Hell",
        fr: "Eiche Hell",
        it: "Eiche Hell"
      },
      color_filter: [
        "Marrone",
        "Arancione",]
    },
    "Tekno_11": {
      code: "3069-037",
      model: "Tekno_11",
      img: "Tekno_11.jpg",
      hex: false,
      name: {
        en: "Winter Douglasie",
        de: "Winter Douglasie",
        fr: "Winter Douglasie",
        it: "Winter Douglasie"
      },
      color_filter: [
        "Marrone",
        "Arancione",]
    },

    "Tekno_12": {
      code: "2178001",
      model: "Tekno_12",
      img: "Tekno_12.jpg",
      hex: false,
      name: {
        en: "Golden Oak",
        de: "Golden Oak",
        fr: "Golden Oak",
        it: "Golden Oak"
      },
      color_filter: [
        "Marrone",
        "Arancione",]
    },

    "Tekno_15": {
      code: "436-3042",
      model: "Tekno_15",
      img: "Tekno_15.jpg",
      hex: false,
      name: {
        en: "Noce Sorrento Balsamico",
        de: "Noce Sorrento Balsamico",
        fr: "Noce Sorrento Balsamico",
        it: "Noce Sorrento Balsamico"
      },
      color_filter: [
        "Marrone",
        "Arancione",
        "Rosso",]
    },

    "Tekno_16": {
      code: "49233",
      model: "Tekno_16",
      img: "Tekno_16.jpg",
      hex: false,
      name: {
        en: "Siena PR",
        de: "Siena PR",
        fr: "Siena PR",
        it: "Siena PR"
      },
      color_filter: [
        "Marrone",
        "Nero",]
    },

    "Tekno_17": {
      code: "49252",
      model: "Tekno_17",
      img: "Tekno_17.jpg",
      hex: false,
      name: {
        en: "Winchester XC",
        de: "Winchester XC",
        fr: "Winchester XC",
        it: "Winchester XC"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_18": {
      code: "2178007",
      model: "Tekno_18",
      img: "Tekno_18.jpg",
      hex: false,
      name: {
        en: "Nussbaum V",
        de: "Nussbaum V",
        fr: "Nussbaum V",
        it: "Nussbaum V"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_19": {
      code: "436-2035",
      model: "Tekno_19",
      img: "Tekno_19.jpg",
      hex: false,
      name: {
        en: "Staufereiche Terra",
        de: "Staufereiche Terra",
        fr: "Staufereiche Terra",
        it: "Staufereiche Terra"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_20": {
      code: "2052-089",
      model: "Tekno_20",
      img: "Tekno_20.jpg",
      hex: false,
      name: {
        en: "Eiche Dunkel FL - F1",
        de: "Eiche Dunkel FL - F1",
        fr: "Eiche Dunkel FL - F1",
        it: "Eiche Dunkel FL - F1"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_21": {
      code: "3167-004",
      model: "Tekno_21",
      img: "Tekno_21.jpg",
      hex: false,
      name: {
        en: "Mooreiche ST-F",
        de: "Mooreiche ST-F",
        fr: "Mooreiche ST-F",
        it: "Mooreiche ST-F"
      },
      color_filter: [
        "Marrone",
        "Nero",
      ]
    },
    "Tekno_22": {
      code: "436-7030",
      model: "Tekno_22",
      img: "Tekno_22.jpg",
      hex: false,
      name: {
        en: "Verkehrsweiss SFTN",
        de: "Verkehrsweiss SFTN",
        fr: "Verkehrsweiss SFTN",
        it: "Verkehrsweiss SFTN"
      },
      color_filter: [
        "Bianco",
      ]
    },

    "Tekno_23": {
      code: "456-5057",
      model: "Tekno_23",
      img: "Tekno_23.jpg",
      hex: false,
      name: {
        en: "Brillantweiss",
        de: "Brillantweiss",
        fr: "Brillantweiss",
        it: "Brillantweiss"
      },
      color_filter: [
        "Bianco",
      ]
    },

    "Tekno_24": {
      code: "426-1809",
      model: "Tekno_24",
      img: "Tekno_24.jpg",
      hex: false,
      name: {
        en: "White Ahp",
        de: "White Ahp",
        fr: "White Ahp",
        it: "White Ahp"
      },
      color_filter: [
        "Bianco",
      ]
    },

    "Tekno_25": {
      code: "9152-05-168",
      model: "Tekno_25",
      img: "Tekno_25.jpg",
      hex: false,
      name: {
        en: "Goffrato (Weiss)",
        de: "Goffrato (Weiss)",
        fr: "Goffrato (Weiss)",
        it: "Goffrato (Weiss)"
      },
      color_filter: [
        "Bianco"]
    },

    "Tekno_26": {
      code: "1379",
      model: "Tekno_26",
      img: "Tekno_26.jpg",
      hex: false,
      name: {
        en: "Avorio (Creme Weiss)",
        de: "Avorio (Creme Weiss)",
        fr: "Avorio (Creme Weiss)",
        it: "Avorio (Creme Weiss)"
      },
      color_filter: [
        "Bianco",
        "Rosa",]
    },

    "Tekno_27": {
      code: "7251",
      model: "Tekno_27",
      img: "Tekno_27.jpg",
      hex: false,
      name: {
        en: "Lichtgrau",
        de: "Lichtgrau",
        fr: "Lichtgrau",
        it: "Lichtgrau"
      },
      color_filter: [
        "Grigio",
        "Bianco",]
    },

    "Tekno_28": {
      code: "7038",
      model: "Tekno_28",
      img: "Tekno_28.jpg",
      hex: false,
      name: {
        en: "Achatgrau",
        de: "Achatgrau",
        fr: "Achatgrau",
        it: "Achatgrau"
      },
      color_filter: [
        "Grigio"]
    },

    "Tekno_29": {
      code: "7155",
      model: "Tekno_29",
      img: "Tekno_29.jpg",
      hex: false,
      name: {
        en: "Grau",
        de: "Grau",
        fr: "Grau",
        it: "Grau"
      },
      color_filter: [
        "Grigio"]
    },

    "Tekno_31": {
      code: "7016",
      model: "Tekno_31",
      img: "Tekno_31.jpg",
      hex: false,
      name: {
        en: "Anthrazitgrau",
        de: "Anthrazitgrau",
        fr: "Anthrazitgrau",
        it: "Anthrazitgrau"
      },
      color_filter: [
        "Grigio",
        "Nero",
      ]
    },

    "Tekno_32": {
      code: "436-1001",
      model: "Tekno_32",
      img: "Tekno_32.jpg",
      hex: false,
      name: {
        en: "Metbrush Aluminium",
        de: "Metbrush Aluminium",
        fr: "Metbrush Aluminium",
        it: "Metbrush Aluminium"
      },
      color_filter: [
        "Grigio"]
    },

    "Tekno_33": {
      code: "436-1002",
      model: "Tekno_33",
      img: "Tekno_33.jpg",
      hex: false,
      name: {
        en: "Metbrush Silver",
        de: "Metbrush Silver",
        fr: "Metbrush Silver",
        it: "Metbrush Silver"
      },
      color_filter: [
        "Grigio"],
    },

    "Tekno_34": {
      code: "456-3081",
      model: "Tekno_34",
      img: "Tekno_34.jpg",
      hex: false,
      name: {
        en: "Sheffield Oak Light",
        de: "Sheffield Oak Light",
        fr: "Sheffield Oak Light",
        it: "Sheffield Oak Light"
      },
      color_filter: [
        "Giallo",
        "Rosa",
        "Grigio",
      ]
    },

    "Tekno_35": {
      code: "3162-002",
      model: "Tekno_35",
      img: "Tekno_35.jpg",
      hex: false,
      name: {
        en: "Macorè",
        de: "Macorè",
        fr: "Macorè",
        it: "Macorè"
      },
      color_filter: [
        "Marrone"]
    },

    "Tekno_36": {
      code: "2065-021",
      model: "Tekno_36",
      img: "Tekno_36.jpg",
      hex: false,
      name: {
        en: "Sapeli",
        de: "Sapeli",
        fr: "Sapeli",
        it: "Sapeli"
      },
      color_filter: [
        "Marrone",
        "Nero",]
    },

    "Tekno_37": {
      code: "436-5020",
      model: "Tekno_37",
      img: "Tekno_37.jpg",
      hex: false,
      name: {
        en: "Pastellgruen",
        de: "Pastellgruen",
        fr: "Pastellgruen",
        it: "Pastellgruen"
      },
      color_filter: [
        "Verde"]
    },

    "Tekno_38": {
      code: "6005",
      model: "Tekno_38",
      img: "Tekno_38.jpg",
      hex: false,
      name: {
        en: "Moosgrun",
        de: "Moosgrun",
        fr: "Moosgrun",
        it: "Moosgrun"
      },
      color_filter: [
        "Verde"]
    },

    "Tekno_39": {
      code: "6125-05-167",
      model: "Tekno_39",
      img: "Tekno_39.jpg",
      hex: false,
      name: {
        en: "Dunkelgruen",
        de: "Dunkelgruen",
        fr: "Dunkelgruen",
        it: "Dunkelgruen"
      },
      color_filter: [
        "Verde",
        "Nero",]
    },

    "Tekno_41": {
      code: "3054",
      model: "Tekno_41",
      img: "Tekno_41.jpg",
      hex: false,
      name: {
        en: "Hell Rot",
        de: "Hell Rot",
        fr: "Hell Rot",
        it: "Hell Rot"
      },
      color_filter: [
        "Rosso"]
    },

    "Tekno_42": {
      code: "3081",
      model: "Tekno_42",
      img: "Tekno_42.jpg",
      hex: false,
      name: {
        en: "Dunkelrot",
        de: "Dunkelrot",
        fr: "Dunkelrot",
        it: "Dunkelrot"
      },
      color_filter: [
        "Rosso"]
    },

    "Tekno_43": {
      code: "3005",
      model: "Tekno_43",
      img: "Tekno_43.jpg",
      hex: false,
      name: {
        en: "Weinrot",
        de: "Weinrot",
        fr: "Weinrot",
        it: "Weinrot"
      },
      color_filter: [
        "Rosso",
        "Nero",
      ]
    },

  },
  label_PVC_Material: {
    "RAL_1000": {
      code: "RAL 1000",
      model: "RAL_1000",
      hex: "#CDBA88",
      img: "RAL_1000.jpg",
      name: {
        en: "Green beige",
        de: "Grünbeige",
        fr: "Beige vert",
        it: "Beige verdastro",
      },
      color_filter: [
        "Giallo",
        "Grigio",
        "Bianco"
      ]
    },
    "RAL_1001": {
      code: "RAL 1001",
      model: "RAL_1001",
      hex: "#D0B084",
      img: "RAL_1001.jpg",
      name: {
        en: "Beige",
        de: "Beige",
        fr: "Beige",
        it: "Beige",
      },
      color_filter: [
        "Giallo",
        "Grigio"
      ]
    },
    "RAL_1002": {
      code: "RAL 1002",
      model: "RAL_1002",
      hex: "#D2AA6D",
      img: "RAL_1002.jpg",
      name: {
        en: "Sand yellow",
        de: "Sandgelb",
        fr: "Jaune sable",
        it: "Giallo sabbia",
      },
      color_filter: [
        "Giallo"
      ]
    },
    "RAL_1003": {
      code: "RAL 1003",
      model: "RAL_1003",
      hex: "#F9A900",
      img: "RAL_1003.jpg",
      name: {
        en: "Signal yellow",
        de: "Signalgelb",
        fr: "Jaune de sécurité",
        it: "Giallo segnale",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1004": {
      code: "RAL 1004",
      model: "RAL_1004",
      hex: "#E49E00",
      img: "RAL_1004.jpg",
      name: {
        en: "Golden yellow",
        de: "Goldgelb",
        fr: "Jaune or",
        it: "Giallo oro",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1005": {
      code: "RAL 1005",
      model: "RAL_1005",
      hex: "#CB8F00",
      img: "RAL_1005.jpg",
      name: {
        en: "Honey yellow",
        de: "Honiggelb",
        fr: "Jaune miel",
        it: "Giallo miele",
      },
      color_filter: [
        "Giallo",
        "Marrone"
      ]
    },
    "RAL_1006": {
      code: "RAL 1006",
      model: "RAL_1006",
      hex: "#E19000",
      img: "RAL_1006.jpg",
      name: {
        en: "Maize yellow",
        de: "Maisgelb",
        fr: "Jaune maïs",
        it: "Giallo polenta",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1007": {
      code: "RAL 1007",
      model: "RAL_1007",
      hex: "#E88C00",
      img: "RAL_1007.jpg",
      name: {
        en: "Daffodil yellow",
        de: "Narzissengelb",
        fr: "Jaune narcisse",
        it: "Giallo narciso",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1011": {
      code: "RAL 1011",
      model: "RAL_1011",
      hex: "#AF8050",
      img: "RAL_1011.jpg",
      name: {
        en: "Brown beige",
        de: "Braunbeige",
        fr: "Beige brun",
        it: "Beige marrone",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_1012": {
      code: "RAL 1012",
      model: "RAL_1012",
      hex: "#DDAF28",
      img: "RAL_1012.jpg",
      name: {
        en: "Lemon yellow",
        de: "Zitronengelb",
        fr: "Jaune citron",
        it: "Giallo limone",
      },
      color_filter: [
        "Giallo"
      ]
    },
    "RAL_1013": {
      code: "RAL 1013",
      model: "RAL_1013",
      hex: "#E3D9C7",
      img: "RAL_1013.jpg",
      name: {
        en: "Oyster white",
        de: "Perlweiß",
        fr: "Blanc perlé",
        it: "Bianco perla",
      },
      color_filter: [
        "Grigio",
        "Bianco"
      ]
    },
    "RAL_1014": {
      code: "RAL 1014",
      model: "RAL_1014",
      hex: "#DDC49B",
      img: "RAL_1014.jpg",
      name: {
        en: "Ivory",
        de: "Elfenbein",
        fr: "Ivoire",
        it: "Avorio",
      },
      color_filter: [
        "Grigio",
        "Giallo"
      ]
    },
    "RAL_1015": {
      code: "RAL 1015",
      model: "RAL_1015",
      hex: "#E6D2B5",
      img: "RAL_1015.jpg",
      name: {
        en: "Light ivory",
        de: "Hellelfenbein",
        fr: "Ivoire clair",
        it: "Avorio chiaro",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_1016": {
      code: "RAL 1016",
      model: "RAL_1016",
      hex: "#F1DD39",
      img: "RAL_1016.jpg",
      name: {
        en: "Sulfur yellow",
        de: "Schwefelgelb",
        fr: "Jaune soufre",
        it: "Giallo zolfo",
      },
      color_filter: [
        "Giallo"
      ]
    },
    "RAL_1017": {
      code: "RAL 1017",
      model: "RAL_1017",
      hex: "#F6A951",
      img: "RAL_1017.jpg",
      name: {
        en: "Saffron yellow",
        de: "Safrangelb",
        fr: "Jaune safran",
        it: "Giallo zafferano",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1018": {
      code: "RAL 1018",
      model: "RAL_1018",
      hex: "#FACA31",
      img: "RAL_1018.jpg",
      name: {
        en: "Zinc yellow",
        de: "Zinkgelb",
        fr: "Jaune zinc",
        it: "Giallo zinco",
      },
      color_filter: [
        "Giallo"
      ]
    },
    "RAL_1019": {
      code: "RAL 1019",
      model: "RAL_1019",
      hex: "#A48F7A",
      img: "RAL_1019.jpg",
      name: {
        en: "Grey beige",
        de: "Graubeige",
        fr: "Beige gris",
        it: "Beige grigiastro",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_1020": {
      code: "RAL 1020",
      model: "RAL_1020",
      hex: "#A08F65",
      img: "RAL_1020.jpg",
      name: {
        en: "Olive yellow",
        de: "Olivgelb",
        fr: "Jaune olive",
        it: "Giallo olivastro",
      },
      color_filter: [
        "Grigio",
        "Verde",
        "Giallo"
      ]
    },
    "RAL_1021": {
      code: "RAL 1021",
      model: "RAL_1021",
      hex: "#F6B600",
      img: "RAL_1021.jpg",
      name: {
        en: "Colza yellow",
        de: "Rapsgelb",
        fr: "Jaune colza",
        it: "Giallo navone",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1023": {
      code: "RAL 1023",
      model: "RAL_1023",
      hex: "#F7B500",
      img: "RAL_1023.jpg",
      name: {
        en: "Traffic yellow",
        de: "Verkehrsgelb",
        fr: "Jaune signalisation",
        it: "Giallo traffico",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_1024": {
      code: "RAL 1024",
      model: "RAL_1024",
      hex: "#BA8F4C",
      img: "RAL_1024.jpg",
      name: {
        en: "Ochre yellow",
        de: "Ockergelb",
        fr: "Jaune ocre",
        it: "Giallo ocra",
      },
      color_filter: [
        "Giallo",
        "Marrone"
      ]
    },
    "RAL_1026": {
      code: "RAL 1026",
      model: "RAL_1026",
      hex: "#FFFF00",
      img: "RAL_1026.jpg",
      name: {
        en: "Luminous yellow",
        de: "Leuchtgelb",
        fr: "Jaune brillant",
        it: "Giallo brillante",
      },
      color_filter: [
        "Giallo"
      ]
    },
    "RAL_1027": {
      code: "RAL 1027",
      model: "RAL_1027",
      hex: "#A77F0F",
      img: "RAL_1027.jpg",
      name: {
        en: "Curry",
        de: "Currygelb",
        fr: "Jaune curry",
        it: "Giallo curry",
      },
      color_filter: [
        "Giallo",
        "Marrone"
      ]
    },
    "RAL_1028": {
      code: "RAL 1028",
      model: "RAL_1028",
      hex: "#FF9C00",
      img: "RAL_1028.jpg",
      name: {
        en: "Melon yellow",
        de: "Melonengelb",
        fr: "Jaune melon",
        it: "Giallo melone",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_1032": {
      code: "RAL 1032",
      model: "RAL_1032",
      hex: "#E2A300",
      img: "RAL_1032.jpg",
      name: {
        en: "Broom yellow",
        de: "Ginstergelb",
        fr: "Jaune genêt",
        it: "Giallo scopa",
      },
      color_filter: [
        "Arancione",
        "Giallo"
      ]
    },
    "RAL_1033": {
      code: "RAL 1033",
      model: "RAL_1033",
      hex: "#F99A1D",
      img: "RAL_1033.jpg",
      name: {
        en: "Dahlia yellow",
        de: "Dahliengelb",
        fr: "Jaune dahlia",
        it: "Giallo dalia",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_1034": {
      code: "RAL 1034",
      model: "RAL_1034",
      hex: "#EB9C52",
      img: "RAL_1034.jpg",
      name: {
        en: "Pastel yellow",
        de: "Pastellgelb",
        fr: "Jaune pastel",
        it: "Giallo pastello",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_1035": {
      code: "RAL 1035",
      model: "RAL_1035",
      hex: "#8F8370",
      img: "RAL_1035.jpg",
      name: {
        en: "Pearl beige",
        de: "Perlbeige",
        fr: "Beige nacré",
        it: "Beige perlato",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_1036": {
      code: "RAL 1036",
      model: "RAL_1036",
      hex: "#806440",
      img: "RAL_1036.jpg",
      name: {
        en: "Pearl gold",
        de: "Perlgold",
        fr: "Or nacré",
        it: "Oro perlato",
      },
      color_filter: [
        "Marrone",
        "Grigio"
      ]
    },
    "RAL_1037": {
      code: "RAL 1037",
      model: "RAL_1037",
      hex: "#F09200",
      img: "RAL_1037.jpg",
      name: {
        en: "Sun yellow",
        de: "Sonnengelb",
        fr: "Jaune soleil",
        it: "Giallo sole",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_2000": {
      code: "RAL 2000",
      model: "RAL_2000",
      hex: "#DA6E00",
      img: "RAL_2000.jpg",
      name: {
        en: "Yellow orange",
        de: "Gelborange",
        fr: "Orangé jaune",
        it: "Arancio giallastro",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_2001": {
      code: "RAL 2001",
      model: "RAL_2001",
      hex: "#BA481C",
      img: "RAL_2001.jpg",
      name: {
        en: "Red orange",
        de: "Rotorange",
        fr: "Orangé rouge",
        it: "Arancio rossastro",
      },
      color_filter: [
        "Arancione",
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_2002": {
      code: "RAL 2002",
      model: "RAL_2002",
      hex: "#BF3922",
      img: "RAL_2002.jpg",
      name: {
        en: "Vermilion",
        de: "Blutorange",
        fr: "Orangé sang",
        it: "Arancio sanguigno",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_2003": {
      code: "RAL 2003",
      model: "RAL_2003",
      hex: "#F67829",
      img: "RAL_2003.jpg",
      name: {
        en: "Pastel orange",
        de: "Pastellorange",
        fr: "Orangé pastel",
        it: "Arancio pastello",
      },
      color_filter: [
        "Arancione",
        "Rosso"
      ]
    },
    "RAL_2004": {
      code: "RAL 2004",
      model: "RAL_2004",
      hex: "#E25304",
      img: "RAL_2004.jpg",
      name: {
        en: "Pure orange",
        de: "Reinorange",
        fr: "Orangé pur",
        it: "Arancio puro",
      },
      color_filter: [
        "Rosso",
        "Arancione"
      ]
    },
    "RAL_2005": {
      code: "RAL 2005",
      model: "RAL_2005",
      hex: "#FF4D08",
      img: "RAL_2005.jpg",
      name: {
        en: "Luminous orange",
        de: "Leuchtorange",
        fr: "Orangé brillant",
        it: "Arancio brillante",
      },
      color_filter: [
        "Rosso",
        "Arancione"
      ]
    },
    "RAL_2007": {
      code: "RAL 2007",
      model: "RAL_2007",
      hex: "#FFB200",
      img: "RAL_2007.jpg",
      name: {
        en: "Luminous bright orange",
        de: "Leuchthellorange",
        fr: "Orangé clair brillant",
        it: "Arancio chiaro brillante",
      },
      color_filter: [
        "Giallo",
        "Arancione"
      ]
    },
    "RAL_2008": {
      code: "RAL 2008",
      model: "RAL_2008",
      hex: "#EC6B22",
      img: "RAL_2008.jpg",
      name: {
        en: "Bright red orange",
        de: "Hellrotorange",
        fr: "Orangé rouge clair",
        it: "Rosso arancio chiaro",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_2009": {
      code: "RAL 2009",
      model: "RAL_2009",
      hex: "#DE5308",
      img: "RAL_2009.jpg",
      name: {
        en: "Traffic orange",
        de: "Verkehrsorange",
        fr: "Orangé signalisation",
        it: "Arancio traffico",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_2010": {
      code: "RAL 2010",
      model: "RAL_2010",
      hex: "#D05D29",
      img: "RAL_2010.jpg",
      name: {
        en: "Signal orange",
        de: "Signalorange",
        fr: "Orangé de sécurité",
        it: "Arancio segnale",
      },
      color_filter: [
        "Arancione",
        "Marrone"
      ]
    },
    "RAL_2011": {
      code: "RAL 2011",
      model: "RAL_2011",
      hex: "#E26E0F",
      img: "RAL_2011.jpg",
      name: {
        en: "Deep orange",
        de: "Tieforange",
        fr: "Orangé foncé",
        it: "Arancio profondo",
      },
      color_filter: [
        "Arancione"
      ]
    },
    "RAL_2012": {
      code: "RAL 2012",
      model: "RAL_2012",
      hex: "#D5654E",
      img: "RAL_2012.jpg",
      name: {
        en: "Salmon orange",
        de: "Lachsorange",
        fr: "Orangé saumon",
        it: "Arancio salmone",
      },
      color_filter: [
        "Arancione",
        "Rosa"
      ]
    },
    "RAL_2013": {
      code: "RAL 2013",
      model: "RAL_2013",
      hex: "#923E25",
      img: "RAL_2013.jpg",
      name: {
        en: "Pearl orange",
        de: "Perlorange",
        fr: "Orangé nacré",
        it: "Arancio perlato",
      },
      color_filter: [
        "Rosso",
        "Arancione",
        "Marrone"
      ]
    },
    "RAL_2017": {
      code: "RAL 2017",
      model: "RAL_2017",
      hex: "#FC5500",
      img: "RAL_2017.jpg",
      name: {
        en: "RAL orange",
        de: "RAL orange",
        fr: "Orangé RAL",
        it: "Arancio RAL",
      },
      color_filter: [
        "Rosso",
        "Arancione"
      ]
    },
    "RAL_3000": {
      code: "RAL 3000",
      model: "RAL_3000",
      hex: "#A72920",
      img: "RAL_3000.jpg",
      name: {
        en: "Flame red",
        de: "Feuerrot",
        fr: "Rouge feu",
        it: "Rosso fuoco",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3001": {
      code: "RAL 3001",
      model: "RAL_3001",
      hex: "#9B2423",
      img: "RAL_3001.jpg",
      name: {
        en: "Signal red",
        de: "Signalrot",
        fr: "Rouge de sécurité",
        it: "Rosso segnale",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3002": {
      code: "RAL 3002",
      model: "RAL_3002",
      hex: "#9B2321",
      img: "RAL_3002.jpg",
      name: {
        en: "Carmine red",
        de: "Karminrot",
        fr: "Rouge carmin",
        it: "Rosso carminio",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3003": {
      code: "RAL 3003",
      model: "RAL_3003",
      hex: "#861A22",
      img: "RAL_3003.jpg",
      name: {
        en: "Ruby red",
        de: "Rubinrot",
        fr: "Rouge rubis",
        it: "Rosso rubino",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3004": {
      code: "RAL 3004",
      model: "RAL_3004",
      hex: "#6B1C23",
      img: "RAL_3004.jpg",
      name: {
        en: "Purple red",
        de: "Purpurrot",
        fr: "Rouge pourpre",
        it: "Rosso porpora",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3005": {
      code: "RAL 3005",
      model: "RAL_3005",
      hex: "#59191F",
      img: "RAL_3005.jpg",
      name: {
        en: "Wine red",
        de: "Weinrot",
        fr: "Rouge vin",
        it: "Rosso vino",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3007": {
      code: "RAL 3007",
      model: "RAL_3007",
      hex: "#3E2022",
      img: "RAL_3007.jpg",
      name: {
        en: "Black red",
        de: "Schwarzrot",
        fr: "Rouge noir",
        it: "Rosso nerastro",
      },
      color_filter: [
        "Rosso",
        "Marrone",
        "Nero"
      ]
    },
    "RAL_3009": {
      code: "RAL 3009",
      model: "RAL_3009",
      hex: "#6D342D",
      img: "RAL_3009.jpg",
      name: {
        en: "Oxide red",
        de: "Oxidrot",
        fr: "Rouge oxyde",
        it: "Rosso ossido",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3011": {
      code: "RAL 3011",
      model: "RAL_3011",
      hex: "#782423",
      img: "RAL_3011.jpg",
      name: {
        en: "Brown red",
        de: "Braunrot",
        fr: "Rouge brun",
        it: "Rosso marrone",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3012": {
      code: "RAL 3012",
      model: "RAL_3012",
      hex: "#C5856D",
      img: "RAL_3012.jpg",
      name: {
        en: "Beige red",
        de: "Beigerot",
        fr: "Rouge beige",
        it: "Rosso beige",
      },
      color_filter: [
        "Arancione",
        "Rosa",
        "Giallo"
      ]
    },
    "RAL_3013": {
      code: "RAL 3013",
      model: "RAL_3013",
      hex: "#972E25",
      img: "RAL_3013.jpg",
      name: {
        en: "Tomato red",
        de: "Tomatenrot",
        fr: "Rouge tomate",
        it: "Rosso pomodoro",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_3014": {
      code: "RAL 3014",
      model: "RAL_3014",
      hex: "#CB7375",
      img: "RAL_3014.jpg",
      name: {
        en: "Antique pink",
        de: "Altrosa",
        fr: "Vieux rose",
        it: "Rosa antico",
      },
      color_filter: [
        "Rosa"
      ]
    },
    "RAL_3015": {
      code: "RAL 3015",
      model: "RAL_3015",
      hex: "#D8A0A6",
      img: "RAL_3015.jpg",
      name: {
        en: "Light pink",
        de: "Hellrosa",
        fr: "Rose clair",
        it: "Rosa chiaro",
      },
      color_filter: [
        "Rosa"
      ]
    },
    "RAL_3016": {
      code: "RAL 3016",
      model: "RAL_3016",
      hex: "#A63D30",
      img: "RAL_3016.jpg",
      name: {
        en: "Coral red",
        de: "Korallenrot",
        fr: "Rouge corail",
        it: "Rosso corallo",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_3017": {
      code: "RAL 3017",
      model: "RAL_3017",
      hex: "#CA555D",
      img: "RAL_3017.jpg",
      name: {
        en: "Rose",
        de: "Rosé",
        fr: "Rosé",
        it: "Rosato",
      },
      color_filter: [
        "Rosso",
        "Rosa"
      ]
    },
    "RAL_3018": {
      code: "RAL 3018",
      model: "RAL_3018",
      hex: "#C63F4A",
      img: "RAL_3018.jpg",
      name: {
        en: "Strawberry red",
        de: "Erdbeerrot",
        fr: "Rouge fraise",
        it: "Rosso fragola",
      },
      color_filter: [
        "Rosso",
        "Rosa"
      ]
    },
    "RAL_3020": {
      code: "RAL 3020",
      model: "RAL_3020",
      hex: "#BB1F11",
      img: "RAL_3020.jpg",
      name: {
        en: "Traffic red",
        de: "Verkehrsrot",
        fr: "Rouge signalisation",
        it: "Rosso traffico",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3022": {
      code: "RAL 3022",
      model: "RAL_3022",
      hex: "#CF6955",
      img: "RAL_3022.jpg",
      name: {
        en: "Salmon pink",
        de: "Lachsrot",
        fr: "Rouge saumon",
        it: "Rosso salmone",
      },
      color_filter: [
        "Arancione",
        "Rosso"
      ]
    },
    "RAL_3024": {
      code: "RAL 3024",
      model: "RAL_3024",
      hex: "#FF2D21",
      img: "RAL_3024.jpg",
      name: {
        en: "Luminous red",
        de: "Leuchtrot",
        fr: "Rouge brillant",
        it: "Rosso brillante",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_3026": {
      code: "RAL 3026",
      model: "RAL_3026",
      hex: "#FF2A1C",
      img: "RAL_3026.jpg",
      name: {
        en: "Luminous bright red",
        de: "Leuchthellrot",
        fr: "Rouge clair brillant",
        it: "Rosso chiaro brillante",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_3027": {
      code: "RAL 3027",
      model: "RAL_3027",
      hex: "#AB273C",
      img: "RAL_3027.jpg",
      name: {
        en: "Raspberry red",
        de: "Himbeerrot",
        fr: "Rouge framboise",
        it: "Rosso lampone",
      },
      color_filter: [
        "Rosso",
        "Viola"
      ]
    },
    "RAL_3028": {
      code: "RAL 3028",
      model: "RAL_3028",
      hex: "#CC2C24",
      img: "RAL_3028.jpg",
      name: {
        en: "Pure red",
        de: "Reinrot",
        fr: "Rouge pu",
        it: "Rosso puro",
      },
      color_filter: [
        "Rosso"
      ]
    },
    "RAL_3031": {
      code: "RAL 3031",
      model: "RAL_3031",
      hex: "#A63437",
      img: "RAL_3031.jpg",
      name: {
        en: "Orient red",
        de: "Orientrot",
        fr: "Rouge oriental",
        it: "Rosso oriente",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3032": {
      code: "RAL 3032",
      model: "RAL_3032",
      hex: "#701D24",
      img: "RAL_3032.jpg",
      name: {
        en: "Pearl ruby red",
        de: "Perlrubinrot",
        fr: "Rouge rubis nacré",
        it: "Rosso rubino perlato",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_3033": {
      code: "RAL 3033",
      model: "RAL_3033",
      hex: "#A53A2E",
      img: "RAL_3033.jpg",
      name: {
        en: "Pearl pink",
        de: "Perlrosa",
        fr: "Rose nacré",
        it: "Rosa perlato",
      },
      color_filter: [
        "Rosso",
        "Marrone"
      ]
    },
    "RAL_4001": {
      code: "RAL 4001",
      model: "RAL_4001",
      hex: "#816183",
      img: "RAL_4001.jpg",
      name: {
        en: "Red lilac",
        de: "Rotlila",
        fr: "Lilas rouge",
        it: "Lilla rossastro",
      },
      color_filter: [
        "Viola"
      ]
    },
    "RAL_4002": {
      code: "RAL 4002",
      model: "RAL_4002",
      hex: "#8D3C4B",
      img: "RAL_4002.jpg",
      name: {
        en: "Red violet",
        de: "Rotviolett",
        fr: "Violet rouge",
        it: "Viola rossastro",
      },
      color_filter: [
        "Viola"
      ]
    },
    "RAL_4003": {
      code: "RAL 4003",
      model: "RAL_4003",
      hex: "#C4618C",
      img: "RAL_4003.jpg",
      name: {
        en: "Heather violet",
        de: "Erikaviolett",
        fr: "Violet bruyère",
        it: "Viola erica",
      },
      color_filter: [
        "Viola",
        "Rosa"
      ]
    },
    "RAL_4004": {
      code: "RAL 4004",
      model: "RAL_4004",
      hex: "#651E38",
      img: "RAL_4004.jpg",
      name: {
        en: "Claret violet",
        de: "Bordeauxviolett",
        fr: "Violet bordeaux",
        it: "Viola bordeaux",
      },
      color_filter: [
        "Viola",
        "Marrone"
      ]
    },
    "RAL_4005": {
      code: "RAL 4005",
      model: "RAL_4005",
      hex: "#76689A",
      img: "RAL_4005.jpg",
      name: {
        en: "Blue lilac",
        de: "Blaulila",
        fr: "Lilas bleu",
        it: "Lilla bluastro",
      },
      color_filter: [
        "Viola",
        "Celeste",
        "Rosa"
      ]
    },
    "RAL_4006": {
      code: "RAL 4006",
      model: "RAL_4006",
      hex: "#903373",
      img: "RAL_4006.jpg",
      name: {
        en: "Traffic purple",
        de: "Verkehrspurpur",
        fr: "Pourpre signalisation",
        it: "Porpora traffico",
      },
      color_filter: [
        "Viola",
        "Rosa"
      ]
    },
    "RAL_4007": {
      code: "RAL 4007",
      model: "RAL_4007",
      hex: "#47243C",
      img: "RAL_4007.jpg",
      name: {
        en: "Purple violet",
        de: "Purpurviolett",
        fr: "Violet pourpre",
        it: "Porpora violetto",
      },
      color_filter: [
        "Viola",
        "Nero"
      ]
    },
    "RAL_4008": {
      code: "RAL 4008",
      model: "RAL_4008",
      hex: "#844C82",
      img: "RAL_4008.jpg",
      name: {
        en: "Signal violet",
        de: "Signalviolett",
        fr: "Violet de sécurité",
        it: "Violetto segnale",
      },
      color_filter: [
        "Viola",
        "Rosa"
      ]
    },
    "RAL_4009": {
      code: "RAL 4009",
      model: "RAL_4009",
      hex: "#9D8692",
      img: "RAL_4009.jpg",
      name: {
        en: "Pastel violet",
        de: "Pastellviolett",
        fr: "Violet pastel",
        it: "Violetto pastello",
      },
      color_filter: [
        "Viola",
        "Rosa",
        "Grigio"
      ]
    },
    "RAL_4010": {
      code: "RAL 4010",
      model: "RAL_4010",
      hex: "#BB4077",
      img: "RAL_4010.jpg",
      name: {
        en: "Telemagenta",
        de: "Telemagenta",
        fr: "Telemagenta",
        it: "Tele Magenta",
      },
      color_filter: [
        "Viola",
        "Rosa"
      ]
    },
    "RAL_4011": {
      code: "RAL 4011",
      model: "RAL_4011",
      hex: "#6E6387",
      img: "RAL_4011.jpg",
      name: {
        en: "Pearl violet",
        de: "Perlviolett",
        fr: "Violet nacré",
        it: "Violetto perlato",
      },
      color_filter: [
        "Viola",
        "Grigio"
      ]
    },
    "RAL_4012": {
      code: "RAL 4012",
      model: "RAL_4012",
      hex: "#6A6B7F",
      img: "RAL_4012.jpg",
      name: {
        en: "Pearl blackberry",
        de: "Perlbrombeer",
        fr: "Mûre nacré",
        it: "Mora perlato",
      },
      color_filter: [
        "Viola",
        "Blu",
        "Grigio"
      ]
    },
    "RAL_5000": {
      code: "RAL 5000",
      model: "RAL_5000",
      hex: "#304F6E",
      img: "RAL_5000.jpg",
      name: {
        en: "Violet blue",
        de: "Violettblau",
        fr: "Bleu violet",
        it: "Blu violaceo",
      },
      color_filter: [
        "Blu",
        "Grigio"
      ]
    },
    "RAL_5001": {
      code: "RAL 5001",
      model: "RAL_5001",
      hex: "#0E4C64",
      img: "RAL_5001.jpg",
      name: {
        en: "Green blue",
        de: "Grünblau",
        fr: "Bleu vert",
        it: "Blu verdastro",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5002": {
      code: "RAL 5002",
      model: "RAL_5002",
      hex: "#00387A",
      img: "RAL_5002.jpg",
      name: {
        en: "Ultramarine blue",
        de: "Ultramarinblau",
        fr: "Bleu outremer",
        it: "Blu oltremare",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5003": {
      code: "RAL 5003",
      model: "RAL_5003",
      hex: "#1F3855",
      img: "RAL_5003.jpg",
      name: {
        en: "Sapphire blue",
        de: "Saphirblau",
        fr: "Bleu saphir",
        it: "Blu zaffiro",
      },
      color_filter: [
        "Blu",
        "Nero"
      ]
    },
    "RAL_5004": {
      code: "RAL 5004",
      model: "RAL_5004",
      hex: "#191E28",
      img: "RAL_5004.jpg",
      name: {
        en: "Black blue",
        de: "Schwarzblau",
        fr: "Bleu noir",
        it: "Blu nerastro",
      },
      color_filter: [
        "Blu",
        "Nero"
      ]
    },
    "RAL_5005": {
      code: "RAL 5005",
      model: "RAL_5005",
      hex: "#005387",
      img: "RAL_5005.jpg",
      name: {
        en: "Signal blue",
        de: "Signalblau",
        fr: "Bleu de sécurité",
        it: "Blu segnale",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5007": {
      code: "RAL 5007",
      model: "RAL_5007",
      hex: "#376B8C",
      img: "RAL_5007.jpg",
      name: {
        en: "Brillant blue",
        de: "Brillantblau",
        fr: "Bleu brillant",
        it: "Blu brillante",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5008": {
      code: "RAL 5008",
      model: "RAL_5008",
      hex: "#2B3A44",
      img: "RAL_5008.jpg",
      name: {
        en: "Grey blue",
        de: "Graublau",
        fr: "Bleu gris",
        it: "Blu grigiastro",
      },
      color_filter: [
        "Blu",
        "Grigio"
      ]
    },
    "RAL_5009": {
      code: "RAL 5009",
      model: "RAL_5009",
      hex: "#215F78",
      img: "RAL_5009.jpg",
      name: {
        en: "Azure blue",
        de: "Azurblau",
        fr: "Bleu azur",
        it: "Blu azzurro",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5010": {
      code: "RAL 5010",
      model: "RAL_5010",
      hex: "#004F7C",
      img: "RAL_5010.jpg",
      name: {
        en: "Gentian blue",
        de: "Enzianblau",
        fr: "Bleu gentiane",
        it: "Blu genziana",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5011": {
      code: "RAL 5011",
      model: "RAL_5011",
      hex: "#1A2B3C",
      img: "RAL_5011.jpg",
      name: {
        en: "Steel blue",
        de: "Stahlblau",
        fr: "Bleu acier",
        it: "Blu acciaio",
      },
      color_filter: [
        "Blu",
        "Nero"
      ]
    },
    "RAL_5012": {
      code: "RAL 5012",
      model: "RAL_5012",
      hex: "#0089B6",
      img: "RAL_5012.jpg",
      name: {
        en: "Light blue",
        de: "Lichtblau",
        fr: "Bleu clair",
        it: "Blu luce",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5013": {
      code: "RAL 5013",
      model: "RAL_5013",
      hex: "#193153",
      img: "RAL_5013.jpg",
      name: {
        en: "Cobalt blue",
        de: "Kobaltblau",
        fr: "Bleu cobalt",
        it: "Blu cobalto",
      },
      color_filter: [
        "Blu",
        "Nero"
      ]
    },
    "RAL_5014": {
      code: "RAL 5014",
      model: "RAL_5014",
      hex: "#637D96",
      img: "RAL_5014.jpg",
      name: {
        en: "Pigeon blue",
        de: "Taubenblau",
        fr: "Bleu pigeon",
        it: "Blu colomba",
      },
      color_filter: [
        "Blu",
        "Grigio"
      ]
    },
    "RAL_5015": {
      code: "RAL 5015",
      model: "RAL_5015",
      hex: "#007CAF",
      img: "RAL_5015.jpg",
      name: {
        en: "Sky blue",
        de: "Himmelblau",
        fr: "Bleu ciel",
        it: "Blu cielo",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5017": {
      code: "RAL 5017",
      model: "RAL_5017",
      hex: "#005B8C",
      img: "RAL_5017.jpg",
      name: {
        en: "Traffic blue",
        de: "Verkehrsblau",
        fr: "Bleu signalisation",
        it: "Blu traffico",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5018": {
      code: "RAL 5018",
      model: "RAL_5018",
      hex: "#048B8C",
      img: "RAL_5018.jpg",
      name: {
        en: "Turquoise blue",
        de: "Türkisblau",
        fr: "Bleu turquoise",
        it: "Blu turchese",
      },
      color_filter: [
        "Blu",
        "Celeste",
        "Verde"
      ]
    },
    "RAL_5019": {
      code: "RAL 5019",
      model: "RAL_5019",
      hex: "#005E83",
      img: "RAL_5019.jpg",
      name: {
        en: "Capri blue",
        de: "Capriblau",
        fr: "Bleu capri",
        it: "Blu capri",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5020": {
      code: "RAL 5020",
      model: "RAL_5020",
      hex: "#00414B",
      img: "RAL_5020.jpg",
      name: {
        en: "Ocean blue",
        de: "Ozeanblau",
        fr: "Bleu océan",
        it: "Blu oceano",
      },
      color_filter: [
        "Verde",
        "Blu"
      ]
    },
    "RAL_5021": {
      code: "RAL 5021",
      model: "RAL_5021",
      hex: "#007577",
      img: "RAL_5021.jpg",
      name: {
        en: "Water blue",
        de: "Wasserblau",
        fr: "Bleu d’eau",
        it: "Blu acqua",
      },
      color_filter: [
        "Celeste",
        "Verde"
      ]
    },
    "RAL_5022": {
      code: "RAL 5022",
      model: "RAL_5022",
      hex: "#222D5A",
      img: "RAL_5022.jpg",
      name: {
        en: "Night blue",
        de: "Nachtblau",
        fr: "Bleu nocturne",
        it: "Blu notte",
      },
      color_filter: [
        "Blu"
      ]
    },
    "RAL_5023": {
      code: "RAL 5023",
      model: "RAL_5023",
      hex: "#41698C",
      img: "RAL_5023.jpg",
      name: {
        en: "Distant blue",
        de: "Fernblau",
        fr: "Bleu distant",
        it: "Blu distante",
      },
      color_filter: [
        "Blu",
        "Grigio"
      ]
    },
    "RAL_5024": {
      code: "RAL 5024",
      model: "RAL_5024",
      hex: "#6093AC",
      img: "RAL_5024.jpg",
      name: {
        en: "Pastel blue",
        de: "Pastellblau",
        fr: "Bleu pastel",
        it: "Blu pastello",
      },
      color_filter: [
        "Celeste",
        "Blu"
      ]
    },
    "RAL_5025": {
      code: "RAL 5025",
      model: "RAL_5025",
      hex: "#20697C",
      img: "RAL_5025.jpg",
      name: {
        en: "Pearl gentian blue",
        de: "Perlenzian",
        fr: "Gentiane nacré",
        it: "Blu genziana perlato",
      },
      color_filter: [
        "Blu",
        "Celeste"
      ]
    },
    "RAL_5026": {
      code: "RAL 5026",
      model: "RAL_5026",
      hex: "#0F3052",
      img: "RAL_5026.jpg",
      name: {
        en: "Pearl night blue",
        de: "Perlnachtblau",
        fr: "Bleu nuit nacré",
        it: "Blu notte perlato",
      },
      color_filter: [
        "Blu",
        "Nero"
      ]
    },
    "RAL_6000": {
      code: "RAL 6000",
      model: "RAL_6000",
      hex: "#3C7460",
      img: "RAL_6000.jpg",
      name: {
        en: "Patina green",
        de: "Patinagrün",
        fr: "Vert patine",
        it: "Verde patina",
      },
      color_filter: [
        "Verde",
        "Celeste"
      ]
    },
    "RAL_6001": {
      code: "RAL 6001",
      model: "RAL_6001",
      hex: "#366735",
      img: "RAL_6001.jpg",
      name: {
        en: "Emerald green",
        de: "Smaragdgrün",
        fr: "Vert émeraude",
        it: "Verde smeraldo",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6002": {
      code: "RAL 6002",
      model: "RAL_6002",
      hex: "#325928",
      img: "RAL_6002.jpg",
      name: {
        en: "Leaf green",
        de: "Laubgrün",
        fr: "Vert feuillage",
        it: "Verde foglia",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6003": {
      code: "RAL 6003",
      model: "RAL_6003",
      hex: "#50533C",
      img: "RAL_6003.jpg",
      name: {
        en: "Olive green",
        de: "Olivgrün",
        fr: "Vert olive",
        it: "Verde oliva",
      },
      color_filter: [
        "Verde",
        "Marrone"
      ]
    },
    "RAL_6004": {
      code: "RAL 6004",
      model: "RAL_6004",
      hex: "#024442",
      img: "RAL_6004.jpg",
      name: {
        en: "Blue green",
        de: "Blaugrün",
        fr: "Vert bleu",
        it: "Verde bluastro",
      },
      color_filter: [
        "Verde",
        "Blu"
      ]
    },
    "RAL_6005": {
      code: "RAL 6005",
      model: "RAL_6005",
      hex: "#114232",
      img: "RAL_6005.jpg",
      name: {
        en: "Moss green",
        de: "Moosgrün",
        fr: "Vert mousse",
        it: "Verde muschio",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6006": {
      code: "RAL 6006",
      model: "RAL_6006",
      hex: "#3C392E",
      img: "RAL_6006.jpg",
      name: {
        en: "Grey olive",
        de: "Grauoliv",
        fr: "Olive gris",
        it: "Oliva grigiastro",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_6007": {
      code: "RAL 6007",
      model: "RAL_6007",
      hex: "#2C3222",
      img: "RAL_6007.jpg",
      name: {
        en: "Bottle green",
        de: "Flaschengrün",
        fr: "Vert bouteille",
        it: "Verde bottiglia",
      },
      color_filter: [
        "Marrone",
        "Nero"
      ]
    },
    "RAL_6008": {
      code: "RAL 6008",
      model: "RAL_6008",
      hex: "#36342A",
      img: "RAL_6008.jpg",
      name: {
        en: "Brown green",
        de: "Braungrün",
        fr: "Vert brun",
        it: "Verde brunastro",
      },
      color_filter: [
        "Marrone",
        "Nero"
      ]
    },
    "RAL_6009": {
      code: "RAL 6009",
      model: "RAL_6009",
      hex: "#27352A",
      img: "RAL_6009.jpg",
      name: {
        en: "Fir green",
        de: "Tannengrün",
        fr: "Vert sapin",
        it: "Verde abete",
      },
      color_filter: [
        "Marrone",
        "Nero"
      ]
    },
    "RAL_6010": {
      code: "RAL 6010",
      model: "RAL_6010",
      hex: "#4D6F39",
      img: "RAL_6010.jpg",
      name: {
        en: "Grass green",
        de: "Grasgrün",
        fr: "Vert herbe",
        it: "Verde erba",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6011": {
      code: "RAL 6011",
      model: "RAL_6011",
      hex: "#6B7C59",
      img: "RAL_6011.jpg",
      name: {
        en: "Reseda green",
        de: "Resedagrün",
        fr: "Vert réséda",
        it: "Verde reseda",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6012": {
      code: "RAL 6012",
      model: "RAL_6012",
      hex: "#2F3D3A",
      img: "RAL_6012.jpg",
      name: {
        en: "Black green",
        de: "Schwarzgrün",
        fr: "Vert noir",
        it: "Verde nerastro",
      },
      color_filter: [
        "Verde",
        "Nero"
      ]
    },
    "RAL_6013": {
      code: "RAL 6013",
      model: "RAL_6013",
      hex: "#7C765A",
      img: "RAL_6013.jpg",
      name: {
        en: "Reed green",
        de: "Schilfgrün",
        fr: "Vert jonc",
        it: "Verde canna",
      },
      color_filter: [
        "Verde",
        "Giallo",
        "Marrone"
      ]
    },
    "RAL_6014": {
      code: "RAL 6014",
      model: "RAL_6014",
      hex: "#474135",
      img: "RAL_6014.jpg",
      name: {
        en: "Yellow olive",
        de: "Gelboliv",
        fr: "Olive jaune",
        it: "Oliva giallastro",
      },
      color_filter: [
        "Marrone",
        "Verde"
      ]
    },
    "RAL_6015": {
      code: "RAL 6015",
      model: "RAL_6015",
      hex: "#3D3D36",
      img: "RAL_6015.jpg",
      name: {
        en: "Black olive",
        de: "Schwarzoliv",
        fr: "Olive noir",
        it: "Oliva nerastro",
      },
      color_filter: [
        "Marrone",
        "Nero",
        "Grigio"
      ]
    },
    "RAL_6016": {
      code: "RAL 6016",
      model: "RAL_6016",
      hex: "#00694C",
      img: "RAL_6016.jpg",
      name: {
        en: "Turquoise green",
        de: "Türkisgrün",
        fr: "Vert turquoise",
        it: "Verde turchese",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6017": {
      code: "RAL 6017",
      model: "RAL_6017",
      hex: "#587F40",
      img: "RAL_6017.jpg",
      name: {
        en: "May green",
        de: "Maigrün",
        fr: "Vert mai",
        it: "Verde maggio",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6018": {
      code: "RAL 6018",
      model: "RAL_6018",
      hex: "#60993B",
      img: "RAL_6018.jpg",
      name: {
        en: "Yellow green",
        de: "Gelbgrün",
        fr: "Vert jaune",
        it: "Verde giallastro",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6019": {
      code: "RAL 6019",
      model: "RAL_6019",
      hex: "#B9CEAC",
      img: "RAL_6019.jpg",
      name: {
        en: "Pastel green",
        de: "Weißgrün",
        fr: "Vert blanc",
        it: "Verde biancastro",
      },
      color_filter: [
        "Verde",
        "Bianco"
      ]
    },
    "RAL_6020": {
      code: "RAL 6020",
      model: "RAL_6020",
      hex: "#37422F",
      img: "RAL_6020.jpg",
      name: {
        en: "Chrome green",
        de: "Chromoxidgrün",
        fr: "Vert oxyde chromique",
        it: "Verde cromo",
      },
      color_filter: [
        "Verde",
        "Nero",
        "Grigio"
      ]
    },
    "RAL_6021": {
      code: "RAL 6021",
      model: "RAL_6021",
      hex: "#8A9977",
      img: "RAL_6021.jpg",
      name: {
        en: "Pale green",
        de: "Blaßgrün",
        fr: "Vert pâle",
        it: "Verde pallido",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6022": {
      code: "RAL 6022",
      model: "RAL_6022",
      hex: "#3A3327",
      img: "RAL_6022.jpg",
      name: {
        en: "Olive drab",
        de: "Braunoliv",
        fr: "Olive brun",
        it: "Oliva brunastro",
      },
      color_filter: [
        "Marrone",
        "Nero"
      ]
    },
    "RAL_6024": {
      code: "RAL 6024",
      model: "RAL_6024",
      hex: "#008351",
      img: "RAL_6024.jpg",
      name: {
        en: "Traffic green",
        de: "Verkehrsgrün",
        fr: "Vert signalisation",
        it: "Verde traffico",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6025": {
      code: "RAL 6025",
      model: "RAL_6025",
      hex: "#5E6E3B",
      img: "RAL_6025.jpg",
      name: {
        en: "Fern green",
        de: "Farngrün",
        fr: "Vert fougère",
        it: "Verde felce",
      },
      color_filter: [
        "Verde",
        "Marrone"
      ]
    },
    "RAL_6026": {
      code: "RAL 6026",
      model: "RAL_6026",
      hex: "#005F4E",
      img: "RAL_6026.jpg",
      name: {
        en: "Opal green",
        de: "Opalgrün",
        fr: "Vert opale",
        it: "Verde opale",
      },
      color_filter: [
        "Verde",
        "Celeste",
        "Blu"
      ]
    },
    "RAL_6027": {
      code: "RAL 6027",
      model: "RAL_6027",
      hex: "#7EBAB5",
      img: "RAL_6027.jpg",
      name: {
        en: "Light green",
        de: "Lichtgrün",
        fr: "Vert clair",
        it: "Verde chiaro",
      },
      color_filter: [
        "Celeste"
      ]
    },
    "RAL_6028": {
      code: "RAL 6028",
      model: "RAL_6028",
      hex: "#315442",
      img: "RAL_6028.jpg",
      name: {
        en: "Pine green",
        de: "Kieferngrün",
        fr: "Vert pin",
        it: "Verde pino",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6029": {
      code: "RAL 6029",
      model: "RAL_6029",
      hex: "#006F3D",
      img: "RAL_6029.jpg",
      name: {
        en: "Mint green",
        de: "Minzgrün",
        fr: "Vert menthe",
        it: "Verde menta",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6032": {
      code: "RAL 6032",
      model: "RAL_6032",
      hex: "#237F52",
      img: "RAL_6032.jpg",
      name: {
        en: "Signal green",
        de: "Signalgrün",
        fr: "Vert de sécurité",
        it: "Verde segnale",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6033": {
      code: "RAL 6033",
      model: "RAL_6033",
      hex: "#45877F",
      img: "RAL_6033.jpg",
      name: {
        en: "Mint turquoise",
        de: "Minttürkis",
        fr: "Turquoise menthe",
        it: "Turchese menta",
      },
      color_filter: [
        "Celeste",
        "Verde"
      ]
    },
    "RAL_6034": {
      code: "RAL 6034",
      model: "RAL_6034",
      hex: "#7AADAC",
      img: "RAL_6034.jpg",
      name: {
        en: "Pastel turquoise",
        de: "Pastelltürkis",
        fr: "Turquoise pastel",
        it: "Turchese pastello",
      },
      color_filter: [
        "Celeste"
      ]
    },
    "RAL_6035": {
      code: "RAL 6035",
      model: "RAL_6035",
      hex: "#194D25",
      img: "RAL_6035.jpg",
      name: {
        en: "Pearl green",
        de: "Perlgrün",
        fr: "Vert nacré",
        it: "Verde perlato",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6036": {
      code: "RAL 6036",
      model: "RAL_6036",
      hex: "#04574B",
      img: "RAL_6036.jpg",
      name: {
        en: "Pearl opal green",
        de: "Perlopalgrün",
        fr: "Vert opal nacré",
        it: "Verde opalo perlato",
      },
      color_filter: [
        "Verde",
        "Blu"
      ]
    },
    "RAL_6037": {
      code: "RAL 6037",
      model: "RAL_6037",
      hex: "#008B29",
      img: "RAL_6037.jpg",
      name: {
        en: "Pure green",
        de: "Reingrün",
        fr: "Vert pur",
        it: "Verde puro",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6038": {
      code: "RAL 6038",
      model: "RAL_6038",
      hex: "#00B51B",
      img: "RAL_6038.jpg",
      name: {
        en: "Luminous green",
        de: "Leuchtgrün",
        fr: "Vert brillant",
        it: "Verde brillante",
      },
      color_filter: [
        "Verde"
      ]
    },
    "RAL_6039": {
      code: "RAL 6039",
      model: "RAL_6039",
      hex: "#B3C43E",
      img: "RAL_6039.jpg",
      name: {
        en: "Fibrous green",
        de: "Fasergrün",
        fr: "Vert fibre",
        it: "Verde fibra",
      },
      color_filter: [
        "Verde",
        "Giallo"
      ]
    },
    "RAL_7000": {
      code: "RAL 7000",
      model: "RAL_7000",
      hex: "#7A888E",
      img: "RAL_7000.jpg",
      name: {
        en: "Squirrel grey",
        de: "Fehgrau",
        fr: "Gris petit-gris",
        it: "Grigio vaio",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7001": {
      code: "RAL 7001",
      model: "RAL_7001",
      hex: "#8C979C",
      img: "RAL_7001.jpg",
      name: {
        en: "Silver grey",
        de: "Silbergrau",
        fr: "Gris argent",
        it: "Grigio argento",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7002": {
      code: "RAL 7002",
      model: "RAL_7002",
      hex: "#817863",
      img: "RAL_7002.jpg",
      name: {
        en: "Olive grey",
        de: "Olivgrau",
        fr: "Gris olive",
        it: "Grigio olivastro",
      },
      color_filter: [
        "Marrone",
        "Grigio"
      ]
    },
    "RAL_7003": {
      code: "RAL 7003",
      model: "RAL_7003",
      hex: "#797669",
      img: "RAL_7003.jpg",
      name: {
        en: "Moss grey",
        de: "Moosgrau",
        fr: "Gris mousse",
        it: "Grigio muschio",
      },
      color_filter: [
        "Grigio",
        "Marrone"
      ]
    },
    "RAL_7004": {
      code: "RAL 7004",
      model: "RAL_7004",
      hex: "#9A9B9B",
      img: "RAL_7004.jpg",
      name: {
        en: "Signal grey",
        de: "Signalgrau",
        fr: "Gris de sécurité",
        it: "Grigio segnale",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7005": {
      code: "RAL 7005",
      model: "RAL_7005",
      hex: "#6B6E6B",
      img: "RAL_7005.jpg",
      name: {
        en: "Mouse grey",
        de: "Mausgrau",
        fr: "Gris souris",
        it: "Grigio topo",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7006": {
      code: "RAL 7006",
      model: "RAL_7006",
      hex: "#766A5E",
      img: "RAL_7006.jpg",
      name: {
        en: "Beige grey",
        de: "Beigegrau",
        fr: "Gris beige",
        it: "Grigio beige",
      },
      color_filter: [
        "Marrone",
        "Grigio"
      ]
    },
    "RAL_7008": {
      code: "RAL 7008",
      model: "RAL_7008",
      hex: "#745F3D",
      img: "RAL_7008.jpg",
      name: {
        en: "Khaki grey",
        de: "Khakigrau",
        fr: "Gris kaki",
        it: "Grigio kaki",
      },
      color_filter: [
        "Marrone",
        "Grigio"
      ]
    },
    "RAL_7009": {
      code: "RAL 7009",
      model: "RAL_7009",
      hex: "#5D6058",
      img: "RAL_7009.jpg",
      name: {
        en: "Green grey",
        de: "Grüngrau",
        fr: "Gris vert",
        it: "Grigio verdastro",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7010": {
      code: "RAL 7010",
      model: "RAL_7010",
      hex: "#585C56",
      img: "RAL_7010.jpg",
      name: {
        en: "Tarpaulin grey",
        de: "Zeltgrau",
        fr: "Gris tente",
        it: "Grigio tenda",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7011": {
      code: "RAL 7011",
      model: "RAL_7011",
      hex: "#52595D",
      img: "RAL_7011.jpg",
      name: {
        en: "Iron grey",
        de: "Eisengrau",
        fr: "Gris fer",
        it: "Grigio ferro",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7012": {
      code: "RAL 7012",
      model: "RAL_7012",
      hex: "#575D5E",
      img: "RAL_7012.jpg",
      name: {
        en: "Basalt grey",
        de: "Basaltgrau",
        fr: "Gris basalte",
        it: "Grigio basalto",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7013": {
      code: "RAL 7013",
      model: "RAL_7013",
      hex: "#575044",
      img: "RAL_7013.jpg",
      name: {
        en: "Brown grey",
        de: "Braungrau",
        fr: "Gris brun",
        it: "Grigio brunastro",
      },
      color_filter: [
        "Grigio",
        "Marrone"
      ]
    },
    "RAL_7015": {
      code: "RAL 7015",
      model: "RAL_7015",
      hex: "#4F5358",
      img: "RAL_7015.jpg",
      name: {
        en: "Slate grey",
        de: "Schiefergrau",
        fr: "Gris ardoise",
        it: "Grigio ardesia",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7016": {
      code: "RAL 7016",
      model: "RAL_7016",
      hex: "#383E42",
      img: "RAL_7016.jpg",
      name: {
        en: "Anthracite grey",
        de: "Anthrazitgrau",
        fr: "Gris anthracite",
        it: "Grigio antracite",
      },
      color_filter: [
        "Grigio",
        "Nero",
        "Blu"
      ]
    },
    "RAL_7021": {
      code: "RAL 7021",
      model: "RAL_7021",
      hex: "#2F3234",
      img: "RAL_7021.jpg",
      name: {
        en: "Black grey",
        de: "Schwarzgrau",
        fr: "Gris noir",
        it: "Grigio nerastro",
      },
      color_filter: [
        "Nero",
        "Grigio"
      ]
    },
    "RAL_7022": {
      code: "RAL 7022",
      model: "RAL_7022",
      hex: "#4C4A44",
      img: "RAL_7022.jpg",
      name: {
        en: "Umbra grey",
        de: "Umbragrau",
        fr: "Gris terre d’ombre",
        it: "Grigio ombra",
      },
      color_filter: [
        "Grigio",
        "Nero"
      ]
    },
    "RAL_7023": {
      code: "RAL 7023",
      model: "RAL_7023",
      hex: "#808076",
      img: "RAL_7023.jpg",
      name: {
        en: "Concrete grey",
        de: "Betongrau",
        fr: "Gris béton",
        it: "Grigio calcestruzzo",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7024": {
      code: "RAL 7024",
      model: "RAL_7024",
      hex: "#45494E",
      img: "RAL_7024.jpg",
      name: {
        en: "Graphite grey",
        de: "Graphitgrau",
        fr: "Gris graphite",
        it: "Grigio grafite",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7026": {
      code: "RAL 7026",
      model: "RAL_7026",
      hex: "#374345",
      img: "RAL_7026.jpg",
      name: {
        en: "Granite grey",
        de: "Granitgrau",
        fr: "Gris granit",
        it: "Grigio granito",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7030": {
      code: "RAL 7030",
      model: "RAL_7030",
      hex: "#928E85",
      img: "RAL_7030.jpg",
      name: {
        en: "Stone grey",
        de: "Steingrau",
        fr: "Gris pierre",
        it: "Grigio pietra",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7031": {
      code: "RAL 7031",
      model: "RAL_7031",
      hex: "#5B686D",
      img: "RAL_7031.jpg",
      name: {
        en: "Blue grey",
        de: "Blaugrau",
        fr: "Gris bleu",
        it: "Grigio bluastro",
      },
      color_filter: [
        "Grigio",
        "Blu"
      ]
    },
    "RAL_7032": {
      code: "RAL 7032",
      model: "RAL_7032",
      hex: "#B5B0A1",
      img: "RAL_7032.jpg",
      name: {
        en: "Pebble grey",
        de: "Kieselgrau",
        fr: "Gris silex",
        it: "Grigio ghiaia",
      },
      color_filter: [
        "Grigio",
        "Giallo"
      ]
    },
    "RAL_7033": {
      code: "RAL 7033",
      model: "RAL_7033",
      hex: "#7F8274",
      img: "RAL_7033.jpg",
      name: {
        en: "Cement grey",
        de: "Zementgrau",
        fr: "Gris ciment",
        it: "Grigio cemento",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7034": {
      code: "RAL 7034",
      model: "RAL_7034",
      hex: "#92886F",
      img: "RAL_7034.jpg",
      name: {
        en: "Yellow grey",
        de: "Gelbgrau",
        fr: "Gris jaune",
        it: "Grigio giallastro",
      },
      color_filter: [
        "Grigio",
        "Giallo"
      ]
    },
    "RAL_7035": {
      code: "RAL 7035",
      model: "RAL_7035",
      hex: "#C5C7C4",
      img: "RAL_7035.jpg",
      name: {
        en: "Light grey",
        de: "Lichtgrau",
        fr: "Gris clair",
        it: "Grigio luce",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7036": {
      code: "RAL 7036",
      model: "RAL_7036",
      hex: "#979392",
      img: "RAL_7036.jpg",
      name: {
        en: "Platinum grey",
        de: "Platingrau",
        fr: "Gris platine",
        it: "Grigio platino",
      },
      color_filter: [
        "Grigio",
        "Rosa"
      ]
    },
    "RAL_7037": {
      code: "RAL 7037",
      model: "RAL_7037",
      hex: "#7A7B7A",
      img: "RAL_7037.jpg",
      name: {
        en: "Dusty grey",
        de: "Staubgrau",
        fr: "Gris poussière",
        it: "Grigio polvere",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7038": {
      code: "RAL 7038",
      model: "RAL_7038",
      hex: "#B0B0A9",
      img: "RAL_7038.jpg",
      name: {
        en: "Agate grey",
        de: "Achatgrau",
        fr: "Gris agate",
        it: "Grigio agata",
      },
      color_filter: [
        "Grigio",
        "Bianco"
      ]
    },
    "RAL_7039": {
      code: "RAL 7039",
      model: "RAL_7039",
      hex: "#6B665E",
      img: "RAL_7039.jpg",
      name: {
        en: "Quartz grey",
        de: "Quarzgrau",
        fr: "Gris quartz",
        it: "Grigio quarzo",
      },
      color_filter: [
        "Grigio",
        "Marrone"
      ]
    },
    "RAL_7040": {
      code: "RAL 7040",
      model: "RAL_7040",
      hex: "#989EA1",
      img: "RAL_7040.jpg",
      name: {
        en: "Window grey",
        de: "Fenstergrau",
        fr: "Gris fenêtre",
        it: "Grigio finestra",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7042": {
      code: "RAL 7042",
      model: "RAL_7042",
      hex: "#8E9291",
      img: "RAL_7042.jpg",
      name: {
        en: "Traffic grey A",
        de: "Verkehrsgrau A",
        fr: "Gris signalisation A",
        it: "Grigio traffico A",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7043": {
      code: "RAL 7043",
      model: "RAL_7043",
      hex: "#4F5250",
      img: "RAL_7043.jpg",
      name: {
        en: "Traffic grey B",
        de: "Verkehrsgrau B",
        fr: "Gris signalisation B",
        it: "Grigio traffico B",
      },
      color_filter: [
        "Grigio",
        "Nero"
      ]
    },
    "RAL_7044": {
      code: "RAL 7044",
      model: "RAL_7044",
      hex: "#B7B3A8",
      img: "RAL_7044.jpg",
      name: {
        en: "Silk grey",
        de: "Seidengrau",
        fr: "Gris soie",
        it: "Grigio seta",
      },
      color_filter: [
        "Grigio",
        "Bianco"
      ]
    },
    "RAL_7045": {
      code: "RAL 7045",
      model: "RAL_7045",
      hex: "#8D9295",
      img: "RAL_7045.jpg",
      name: {
        en: "Telegrey 1",
        de: "Telegrau 1",
        fr: "Telegris 1",
        it: "Grigio tele 1",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7046": {
      code: "RAL 7046",
      model: "RAL_7046",
      hex: "#7E868A",
      img: "RAL_7046.jpg",
      name: {
        en: "Telegrey 2",
        de: "Telegrau 2",
        fr: "Telegris 2",
        it: "Grigio tele 2",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_7047": {
      code: "RAL 7047",
      model: "RAL_7047",
      hex: "#C8C8C7",
      img: "RAL_7047.jpg",
      name: {
        en: "Telegrey 4",
        de: "Telegrau 4",
        fr: "Telegris 4",
        it: "Grigio tele 4",
      },
      color_filter: [
        "Grigio",
        "Bianco"
      ]
    },
    "RAL_7048": {
      code: "RAL 7048",
      model: "RAL_7048",
      hex: "#817B73",
      img: "RAL_7048.jpg",
      name: {
        en: "Pearl mouse grey",
        de: "Perlmausgrau",
        fr: "Gris souris nacré",
        it: "Grigio topo perlato",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_8000": {
      code: "RAL 8000",
      model: "RAL_8000",
      hex: "#89693F",
      img: "RAL_8000.jpg",
      name: {
        en: "Green brown",
        de: "Grünbraun",
        fr: "Brun vert",
        it: "Marrone verdastro",
      },
      color_filter: [
        "Marrone",
        "Arancione"
      ]
    },
    "RAL_8001": {
      code: "RAL 8001",
      model: "RAL_8001",
      hex: "#9D622B",
      img: "RAL_8001.jpg",
      name: {
        en: "Ochre brown",
        de: "Ockerbraun",
        fr: "Brun terre de Sienne",
        it: "Marrone ocra",
      },
      color_filter: [
        "Marrone",
        "Arancione"
      ]
    },
    "RAL_8002": {
      code: "RAL 8002",
      model: "RAL_8002",
      hex: "#794D3E",
      img: "RAL_8002.jpg",
      name: {
        en: "Signal brown",
        de: "Signalbraun",
        fr: "Brun de sécurité",
        it: "Marrone segnale",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_8003": {
      code: "RAL 8003",
      model: "RAL_8003",
      hex: "#7E4B27",
      img: "RAL_8003.jpg",
      name: {
        en: "Clay brown",
        de: "Lehmbraun",
        fr: "Brun argile",
        it: "Marrone fango",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8004": {
      code: "RAL 8004",
      model: "RAL_8004",
      hex: "#8D4931",
      img: "RAL_8004.jpg",
      name: {
        en: "Copper brown",
        de: "Kupferbraun",
        fr: "Brun cuivré",
        it: "Marrone rame",
      },
      color_filter: [
        "Marrone",
        "Rosso"
      ]
    },
    "RAL_8007": {
      code: "RAL 8007",
      model: "RAL_8007",
      hex: "#70462B",
      img: "RAL_8007.jpg",
      name: {
        en: "Fawn brown",
        de: "Rehbraun",
        fr: "Brun fauve",
        it: "Marrone capriolo",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8008": {
      code: "RAL 8008",
      model: "RAL_8008",
      hex: "#724A25",
      img: "RAL_8008.jpg",
      name: {
        en: "Olive brown",
        de: "Olivbraun",
        fr: "Brun olive",
        it: "Marrone oliva",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8011": {
      code: "RAL 8011",
      model: "RAL_8011",
      hex: "#5A3827",
      img: "RAL_8011.jpg",
      name: {
        en: "Nut brown",
        de: "Nußbraun",
        fr: "Brun noisette",
        it: "Marrone noce",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8012": {
      code: "RAL 8012",
      model: "RAL_8012",
      hex: "#66332B",
      img: "RAL_8012.jpg",
      name: {
        en: "Red brown",
        de: "Rotbraun",
        fr: "Brun rouge",
        it: "Marrone rossiccio",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8014": {
      code: "RAL 8014",
      model: "RAL_8014",
      hex: "#4A3526",
      img: "RAL_8014.jpg",
      name: {
        en: "Sepia brown",
        de: "Sepiabraun",
        fr: "Brun sépia",
        it: "Marrone seppia",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8015": {
      code: "RAL 8015",
      model: "RAL_8015",
      hex: "#5E2F26",
      img: "RAL_8015.jpg",
      name: {
        en: "Chestnut brown",
        de: "Kastanienbraun",
        fr: "Marron",
        it: "Marrone castagna",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8016": {
      code: "RAL 8016",
      model: "RAL_8016",
      hex: "#4C2B20",
      img: "RAL_8016.jpg",
      name: {
        en: "Mahogany brown",
        de: "Mahagonibraun",
        fr: "Brun acajou",
        it: "Marrone mogano",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8017": {
      code: "RAL 8017",
      model: "RAL_8017",
      hex: "#442F29",
      img: "RAL_8017.jpg",
      name: {
        en: "Chocolate brown",
        de: "Schokoladenbraun",
        fr: "Brun chocolat",
        it: "Marrone cioccolata",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8019": {
      code: "RAL 8019",
      model: "RAL_8019",
      hex: "#3D3635",
      img: "RAL_8019.jpg",
      name: {
        en: "Grey brown",
        de: "Graubraun",
        fr: "Brun gris",
        it: "Marrone grigiastro",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8022": {
      code: "RAL 8022",
      model: "RAL_8022",
      hex: "#1A1719",
      img: "RAL_8022.jpg",
      name: {
        en: "Black brown",
        de: "Schwarzbraun",
        fr: "Brun noir",
        it: "Marrone nerastro",
      },
      color_filter: [
        "Marrone",
        "Blu",
        "Nero"
      ]
    },
    "RAL_8023": {
      code: "RAL 8023",
      model: "RAL_8023",
      hex: "#A45729",
      img: "RAL_8023.jpg",
      name: {
        en: "Orange brown",
        de: "Orangebraun",
        fr: "Brun orangé",
        it: "Marrone arancio",
      },
      color_filter: [
        "Marrone",
        "Arancione"
      ]
    },
    "RAL_8024": {
      code: "RAL 8024",
      model: "RAL_8024",
      hex: "#795038",
      img: "RAL_8024.jpg",
      name: {
        en: "Beige brown",
        de: "Beigebraun",
        fr: "Brun beige",
        it: "Marrone beige",
      },
      color_filter: [
        "Marrone"
      ]
    },
    "RAL_8025": {
      code: "RAL 8025",
      model: "RAL_8025",
      hex: "#755847",
      img: "RAL_8025.jpg",
      name: {
        en: "Pale brown",
        de: "Blaßbraun",
        fr: "Brun pâle",
        it: "Marrone pallido",
      },
      color_filter: [
        "Marrone",
        "Grigio"
      ]
    },
    "RAL_8028": {
      code: "RAL 8028",
      model: "RAL_8028",
      hex: "#513A2A",
      img: "RAL_8028.jpg",
      name: {
        en: "Terra brown",
        de: "Terrabraun",
        fr: "Brun terre",
        it: "Marrone terra",
      },
      color_filter: [
        "Marrone",
        "Grigio",
        "Nero"
      ]
    },
    "RAL_8029": {
      code: "RAL 8029",
      model: "RAL_8029",
      hex: "#7F4031",
      img: "RAL_8029.jpg",
      name: {
        en: "Pearl copper",
        de: "Perlkupfer",
        fr: "Cuivre nacré",
        it: "Rame perlato",
      },
      color_filter: [
        "Marrone",
        "Rosso"
      ]
    },
    "RAL_9001": {
      code: "RAL 9001",
      model: "RAL_9001",
      hex: "#E9E0D2",
      img: "RAL_9001.jpg",
      name: {
        en: "Cream",
        de: "Cremeweiß",
        fr: "Blanc crème",
        it: "Bianco crema",
      },
      color_filter: [
        "Bianco",
        "Giallo"
      ]
    },
    "RAL_9002": {
      code: "RAL 9002",
      model: "RAL_9002",
      hex: "#D6D5CB",
      img: "RAL_9002.jpg",
      name: {
        en: "Grey white",
        de: "Grauweiß",
        fr: "Blanc gris",
        it: "Bianco grigiastro",
      },
      color_filter: [
        "Bianco",
        "Grigio"
      ]
    },
    "RAL_9003": {
      code: "RAL 9003",
      model: "RAL_9003",
      hex: "#ECECE7",
      img: "RAL_9003.jpg",
      name: {
        en: "Signal white",
        de: "Signalweiß",
        fr: "Blanc de sécurité",
        it: "Bianco segnale",
      },
      color_filter: [
        "Bianco"
      ]
    },
    "RAL_9004": {
      code: "RAL 9004",
      model: "RAL_9004",
      hex: "#2B2B2C",
      img: "RAL_9004.jpg",
      name: {
        en: "Signal black",
        de: "Signalschwarz",
        fr: "Noir de sécurité",
        it: "Nero segnale",
      },
      color_filter: [
        "Grigio",
        "Nero"
      ]
    },
    "RAL_9005": {
      code: "RAL 9005",
      model: "RAL_9005",
      hex: "#0E0E10",
      img: "RAL_9005.jpg",
      name: {
        en: "Jet black",
        de: "Tiefschwarz",
        fr: "Noir foncé",
        it: "Nero intenso",
      },
      color_filter: [
        "Nero"
      ]
    },
    "RAL_9006": {
      code: "RAL 9006",
      model: "RAL_9006",
      hex: "#A1A1A0",
      img: "RAL_9006.jpg",
      name: {
        en: "White aluminium",
        de: "Weißaluminium",
        fr: "Aluminium blanc",
        it: "Alluminio brillante",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_9007": {
      code: "RAL 9007",
      model: "RAL_9007",
      hex: "#868581",
      img: "RAL_9007.jpg",
      name: {
        en: "Grey aluminium",
        de: "Graualuminium",
        fr: "Aluminium gris",
        it: "Alluminio grigiastro",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_9010": {
      code: "RAL 9010",
      model: "RAL_9010",
      hex: "#F1EDE1",
      img: "RAL_9010.jpg",
      name: {
        en: "Pure white",
        de: "Reinweiß",
        fr: "Blanc pur",
        it: "Bianco puro",
      },
      color_filter: [
        "Bianco"
      ]
    },
    "RAL_9011": {
      code: "RAL 9011",
      model: "RAL_9011",
      hex: "#27292B",
      img: "RAL_9011.jpg",
      name: {
        en: "Graphite black",
        de: "Graphitschwarz",
        fr: "Noir graphite",
        it: "Nero grafite",
      },
      color_filter: [
        "Nero"
      ]
    },
    "RAL_9012": {
      code: "RAL 9012",
      model: "RAL_9012",
      hex: "#F8F2E1",
      img: "RAL_9012.jpg",
      name: {
        en: "Cleanroom white",
        de: "Reinraumweiß",
        fr: "Blanc salle blanche",
        it: "Bianco cleanroom",
      },
      color_filter: [
        "Giallo",
        "Bianco"
      ]
    },
    "RAL_9016": {
      code: "RAL 9016",
      model: "RAL_9016",
      hex: "#F1F1EA",
      img: "RAL_9016.jpg",
      name: {
        en: "Traffic white",
        de: "Verkehrsweiß",
        fr: "Blanc signalisation",
        it: "Bianco traffico",
      },
      color_filter: [
        "Bianco"
      ]
    },
    "RAL_9017": {
      code: "RAL 9017",
      model: "RAL_9017",
      hex: "#29292A",
      img: "RAL_9017.jpg",
      name: {
        en: "Traffic black",
        de: "Verkehrsschwarz",
        fr: "Noir signalisation",
        it: "Nero traffico",
      },
      color_filter: [
        "Nero"
      ]
    },
    "RAL_9018": {
      code: "RAL 9018",
      model: "RAL_9018",
      hex: "#C8CBC4",
      img: "RAL_9018.jpg",
      name: {
        en: "Papyrus white",
        de: "Papyrusweiß",
        fr: "Blanc papyrus",
        it: "Bianco papiro",
      },
      color_filter: [
        "Bianco",
        "Grigio",
      ]
    },
    "RAL_9022": {
      code: "RAL 9022",
      model: "RAL_9022",
      hex: "#858583",
      img: "RAL_9022.jpg",
      name: {
        en: "Pearl light grey",
        de: "Perlhellgrau",
        fr: "Gris clair nacré",
        it: "Grigio chiaro perlato",
      },
      color_filter: [
        "Grigio"
      ]
    },
    "RAL_9023": {
      code: "RAL 9023",
      model: "RAL_9023",
      hex: "#787B7A",
      img: "RAL_9023.jpg",
      name: {
        en: "Pearl dark grey",
        de: "Perldunkelgrau",
        fr: "Gris fonçé nacré",
        it: "Grigio scuro perlato",
      },
      color_filter: [
        "Grigio"
      ]
    }
  },
  label_Aluminum_Material: {
    "Verde_P7103_GrMix": {
      code: "Verde P7103 Grinz Mix",
      model: "Verde_P7103_GrMix",
      img: "Verde_P7103_GrMix.jpg",
      hex: false,
      name: {
        en: "Verde P7103 Grinz Mix",
        de: "Verde P7103 Grinz Mix",
        fr: "Verde P7103 Grinz Mix",
        it: "Verde P7103 Grinz Mix",
      },
      color_filter: []
    },
    "Bleu_Ak15V_Grinz": {
      code: "Bleu Ak15V Grinz",
      model: "Bleu_Ak15V_Grinz",
      img: "Bleu_Ak15V_Grinz.jpg",
      hex: false,
      name: {
        en: "Bleu Ak15V Grinz",
        de: "Bleu Ak15V Grinz",
        fr: "Bleu Ak15V Grinz",
        it: "Bleu Ak15V Grinz",
      },
      color_filter: []
    },
    "Corten_V001V_GrinzMix": {
      code: "Corten V001V Grinz Mix",
      model: "Corten_V001V_GrinzMix",
      img: "Corten_V001V_GrinzMix.jpg",
      hex: false,
      name: {
        en: "Corten V001V Grinz Mix",
        de: "Corten V001V Grinz Mix",
        fr: "Corten V001V Grinz Mix",
        it: "Corten V001V Grinz Mix",
      },
      color_filter: []
    },
    "Grigio_P7000_Mix": {
      code: "Grigio P7003 Grinz Mix",
      model: "Grigio_P7000_Mix",
      img: "Grigio_P7000_Mix.jpg",
      hex: false,
      name: {
        en: "Grigio P7003 Grinz Mix",
        de: "Grigio P7003 Grinz Mix",
        fr: "Grigio P7003 Grinz Mix",
        it: "Grigio P7003 Grinz Mix",
      },
      color_filter: []
    },
    "Ral_1013_Grinz": {
      code: "RAL 1013 Grinz",
      model: "Ral_1013_Grinz",
      img: "Ral_1013_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 1013 Grinz",
        de: "RAL 1013 Grinz",
        fr: "RAL 1013 Grinz",
        it: "RAL 1013 Grinz",
      },
      color_filter: []
    },
    "Ral_3003_Grinz": {
      code: "RAL 3003 Grinz",
      model: "Ral_3003_Grinz",
      img: "Ral_3003_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 3003 Grinz",
        de: "RAL 3003 Grinz",
        fr: "RAL 3003 Grinz",
        it: "RAL 3003 Grinz",
      },
      color_filter: []
    },
    "Ral_6005_Grinz": {
      code: "RAL 6005 Grinz",
      model: "Ral_6005_Grinz",
      img: "Ral_6005_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 6005 Grinz",
        de: "RAL 6005 Grinz",
        fr: "RAL 6005 Grinz",
        it: "RAL 6005 Grinz",
      },
      color_filter: []
    },
    "Ral_7001_Grinz": {
      code: "RAL 7001 Grinz",
      model: "Ral_7001_Grinz",
      img: "Ral_7001_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 7001 Grinz",
        de: "RAL 7001 Grinz",
        fr: "RAL 7001 Grinz",
        it: "RAL 7001 Grinz",
      },
      color_filter: []
    },
    "Ral_7016_Grinz": {
      code: "RAL 7016 Grinz",
      model: "Ral_7016_Grinz",
      img: "Ral_7016_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 7016 Grinz",
        de: "RAL 7016 Grinz",
        fr: "RAL 7016 Grinz",
        it: "RAL 7016 Grinz",
      },
      color_filter: []
    },
    "Ral_9010_Grinz": {
      code: "RAL 9010 Grinz",
      model: "Ral_9010_Grinz",
      img: "Ral_9010_Grinz.jpg",
      hex: false,
      name: {
        en: "RAL 9010 Grinz",
        de: "RAL 9010 Grinz",
        fr: "RAL 9010 Grinz",
        it: "RAL 9010 Grinz",
      },
      color_filter: []
    },
    "Salvia_Ak11V_GrinzMix": {
      code: "Salvia Ak11V Grinz Mix",
      model: "Salvia_Ak11V_GrinzMix",
      img: "Salvia_Ak11V_GrinzMix.jpg",
      hex: false,
      name: {
        en: "Salvia Ak11V Grinz Mix",
        de: "Salvia Ak11V Grinz Mix",
        fr: "Salvia Ak11V Grinz Mix",
        it: "Salvia Ak11V Grinz Mix",
      },
      color_filter: []
    },
  }
}
